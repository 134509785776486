<template>
  <div class="home-page">
    <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab"
      img-width="1024" img-height="480" @sliding-start="onSlideStart" @sliding-end="onSlideEnd" next-text=""
      prev-text="">
      <b-carousel-slide :img-src="homePage1" @click.native="goToPage('/White-Wood')"> 
        <div class="carousel-title ">
          <p class="noto noto-title-lg-400 text-left m-0">白+木
            <br>
            White and Wood
          </p>
        </div> 
      </b-carousel-slide>
      <b-carousel-slide :img-src="homePage2" @click.native="goToPage('/White-Wood')">
        <div class="carousel-title ">
          <p class="noto noto-title-lg-400 text-left m-0">白+木
            <br>
            White and Wood
          </p>
        </div>
      </b-carousel-slide>
      <b-carousel-slide :img-src="homePage3" @click.native="goToPage('/Romance-In-The-House')">
        <div class="carousel-title ">
          <p class="noto noto-title-lg-400 text-left m-0">漫乐
            <br>
            Romance in the house
          </p>
        </div>
      </b-carousel-slide>
      <b-carousel-slide :img-src="homePage4"  @click.native="goToPage('/Romance-In-The-House')">
        <div class="carousel-title ">
          <p class="noto noto-title-lg-400 text-left m-0">漫乐
            <br>
            Romance in the house
          </p>
        </div>
      </b-carousel-slide>
      <b-carousel-slide :img-src="homePage5"  @click.native="goToPage('/Perfect-In-Black')">
        <div class="carousel-title ">
          <p class="noto noto-title-lg-400 text-left m-0">墨
            <br>
            Perfect in black
          </p>
        </div>
      </b-carousel-slide>
      <b-carousel-slide :img-src="homePage6"  @click.native="goToPage('/Perfect-In-Black')">
        <div class="carousel-title ">
          <p class="noto noto-title-lg-400 text-left m-0">墨
            <br>
            Perfect in black
          </p>
        </div>
      </b-carousel-slide>
    </b-carousel>
    <div class="works ">
      <div class="amalfi-coast-title amalfi-coast"> 
        <img src="../assets/img/crafted-from-passion.png" class="cafted-text-img" alt="">
      </div>
      <div class="container">
        <div class="row" >
          <router-link class="col-md-6 col-sm-12 pb-56" to="White-Wood"  >
            <img src="../assets/img/homepage/project-01-white+wood.jpg"
              alt="白 + 木 | White + Wood. Semi D, Eco Spring | Johor" >
            <p class="playfair playfair-title-sm-400 black">白 + 木 | White + Wood</p>
            <span class="inter inter-body-md-400 gray-400">USJ 5 | Subang Jaya</span>
          </router-link>
          <router-link class="col-md-6 col-sm-12 pb-56" to="Romance-In-The-House">
            <img src="../assets/img/homepage/project-02-romance-in-the-house.jpg"
              alt="漫乐 | Romance in the house. Eco Spring | Johor">
            <p class="playfair playfair-title-sm-400 black">漫乐 | Romance in the house</p>
            <span class="inter inter-body-md-400 gray-400">Eco Spring | Johor</span>
          </router-link>
          <router-link class="col-md-6 col-sm-12 pb-56" to="Comfort-Zone">
            <img src="../assets/img/homepage/project-03-comfort.jpg" alt="舒窝 | Comfort Zone. Sunway Mont | Mont Kiara">
            <p class="playfair playfair-title-sm-400 black">舒窝 | Comfort Zone</p>
            <span class="inter inter-body-md-400 gray-400">Sunway Mont | Mont Kiara</span>
          </router-link>
          <router-link class="col-md-6 col-sm-12 pb-56" to="Grey-Song">
            <img src="../assets/img/homepage/project-04-grey-song.jpg" alt="岩灰 | Grey Song. Kiaraville | Mont Kiara">
            <p class="playfair playfair-title-sm-400 black">岩灰 | Grey Song</p>
            <span class="inter inter-body-md-400 gray-400">Kiaraville | Mont Kiara</span>
          </router-link>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-button variant="dark" class="inter inter-body-lg-600" to="works"  tag="router-link" >More Works <img
                src="../assets/img/icon/arrow_forward.svg" alt=""></b-button> 
          </div>
        </div>
      </div>
    </div>
    <div class="works-details-row primary-bg">
      <div class="row flex-center">
        <div class="col-12 col-xl-7  ">
          <router-link :to="{ path: '/works', query: { tab: 'bungalow' } }">
            <div class="works-details-img">
              <img src="../assets/img/homepage/type-bungalow.jpg" alt="type-bungalow">
              <div class="works-details-title">
                <p class="playfair title-1">Bungalow</p>
                <p class="noto title-2">独立式</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-xl-7  ">
          <router-link :to="{ path: '/works', query: { tab: 'semiD' } }">
            <div class="works-details-img">
              <img src="../assets/img/homepage/type-semi-d.jpg" alt="type-semi-d">
              <div class="works-details-title">
                <p class="playfair title-1">Semi-Detached</p>
                <p class="noto title-2">半独立</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-xl-7  ">
          <router-link :to="{ path: '/works', query: { tab: 'linkHouseCondo' } }">
            <div class="works-details-img">
              <img src="../assets/img/homepage/type-link-house-condo.jpg" alt="type-link-house-condo">
              <div class="works-details-title">
                <p class="playfair title-1">Link house / Condominium</p>
                <p class="noto title-2">排屋/公寓</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-xl-7  ">
          <router-link :to="{ path: '/works', query: { tab: 'designConcept' } }">
            <div class="works-details-img">
              <img src="../assets/img/homepage/type-concept.jpg" alt="type-concept">
              <div class="works-details-title">
                <p class="playfair title-1">Concept</p>
                <p class="noto title-2">效果图</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="founder-info-row gray-100">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="founder-info-img">
            <img src="../assets/img/homepage/founder-sara.jpg" alt="Founder Sara">
          </div>
          <div class="founder-info">
            <p class="noto noto-title-sm-400">
              <img class="colon-top" src="../assets/img/homepage/colon-top.png" alt="">
              生活水准与品质的提升,源自于不断地创新和提升设计的自身价值,<br>并且坚持.
            </p>
            <p class="playfair playfair-title-sm-400 italic">
              quality of life is enhanced through persistent innovation, and via targeted efforts to increase a design’s
              intrinsic value.
              <img class="colon-bottom" src="../assets/img/homepage/colon-bottom.png" alt="">
            </p>
            <img class="width-100" src="../assets/img/homepage/founder-sara-sign.png" alt="Founder Sara">
            <div class="width-100 flex-wrap text-left inter ">
              <span class="inter-body-md-600">
                SARA LIM
              </span>
              <span class=" inter-body-sm-400">
                FOUNDER OF NINE PLUS INTERIOR DESIGN (M) SDN BHD
              </span>
              <span class=" inter-body-sm-400">
                MANAGING DIRECTOR
              </span>
              <span class=" inter-body-sm-400">
                15 YEARS EXPERIENCES
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="founder-info-row mb-112 gray-100">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="founder-info-img">
            <img src="../assets/img/homepage/founder-christine.jpg" alt="Founder Christine">
          </div>
          <div class="founder-info">
            <p class="noto noto-title-sm-400">
              <img class="colon-top" src="../assets/img/homepage/colon-top.png" alt="">
              设计就是改善和改变是我们的初衷,真正理解和发掘业主的需要,<br>是我们坚持的理念.
            </p>
            <p class="playfair playfair-title-sm-400 italic">
              enhance and innovate is part of our core values. Fully understanding and discovering the client’s needs
              will always be our key design principle.
              <img class="colon-bottom" src="../assets/img/homepage/colon-bottom.png" alt="">
            </p>
            <img class="width-100" src="../assets/img/homepage/founder-chrustine-sign.png" alt="Founder Christine">
            <div class="width-100 flex-wrap text-left inter ">
              <span class="inter-body-md-600">
                CHRISTINE LEE
              </span>
              <span class=" inter-body-sm-400">
                FOUNDER OF NINE PLUS INTERIOR DESIGN (M) SDN BHD
              </span>
              <span class=" inter-body-sm-400">
                CREATIVE DIRECTOR
              </span>
              <span class=" inter-body-sm-400">
                15 YEARS EXPERIENCES
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="awards">
      <div class="amalfi-coast-title amalfi-coast">
        Awards
      </div>
      <div class="awards-img">
        <img src="../assets/img/homepage/awards-01.png" alt="">
        <img src="../assets/img/homepage/awards-02.svg" alt="">
      </div>
    </div>
  </div> 
</template>

<script>   
export default {
  name: 'homeComponent',
  data() {
    return {
      slide: 0, // slide variable used for v-model binding
      sliding: null,
      homePage1: require('@/assets/img/homepage/hero-banner-01-white+wood.jpg'),
      homePage2: require('@/assets/img/homepage/hero-banner-02-white+wood.jpg'),
      homePage3: require('@/assets/img/homepage/hero-banner-03-romance-in-the-house.jpg'),
      homePage4: require('@/assets/img/homepage/hero-banner-04-romance-in-the-house.jpg'),
      homePage5: require('@/assets/img/homepage/hero-banner-05-perfect-in-black.jpg'),
      homePage6: require('@/assets/img/homepage/hero-banner-06-perfect-in-black.jpg'),
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },   
    goToPage(url) {
    this.$router.push(url);
  }
  }
}
</script>

<style>
.home-page .carousel-inner img {
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover;
}

.home-page .carousel-control-next,
.home-page .carousel-control-prev {
  bottom: 16%;
  top: unset;
}

.home-page .carousel-control-next {
  right: 3%;
}

.home-page .carousel-control-prev,
.home-page .carousel-control-next .sr-only,
.home-page .carousel-control-prev .sr-only {
  display: none;
}

.home-page .carousel-caption {
  width: 18%;
  right: 9%;
  left: unset;
  bottom: 13%;
  padding: 0;
}

.carousel-control-next-icon {
  background-image: url('../assets/img/icon/arrow_forward.svg') !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  
}
.carousel-control-next, .carousel-control-prev{
  opacity: 1 !important;
}
.home-page .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #8E8E93;
  opacity: 0.6;
  margin-right: 24px;
  list-style: none;
}

.home-page .carousel-indicators .active {
  background-color: #fff;
  opacity: 1;
}

.works {
  padding-bottom: 112px;
}

.works p {
  margin-bottom: 0;
  padding-top: 24px;
}

.pb-56 {
  padding-bottom: 56px;
}

.works img {
  width: 100%;
}
.works .btn {
  width: 170px;
  height: 52px;
  padding: 15px;
  border-radius: unset;
}
.works .btn img {
  width: 12px;
  height: 12px;
  margin-left: 5px;
}
.works button {
  padding: 16px 24px;
  border-radius: 0;
}

.works button img {
  width: 20px;
  margin-left: 8px;
}

.works-details-row {
  padding: 112px 120px;
}

.works-details-row .title-1 {
  font-size: 30px;
  letter-spacing: 0.5em;
  margin-bottom: 0
}

.works-details-row .title-2 {
  font-size: 16px;
  letter-spacing: 3em;
  padding-left: 70px;
  margin-bottom: 0;
}

.works-details-img {
  position: relative;
  margin-bottom: 56px;

}

.works-details-img img {
  width: 100%;
  height: 311px;
  -o-object-fit: cover;
  object-fit: cover;
}

.works-details-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  color: #F2F2F7;
  width: 100%;
}

.founder-info-row {
  position: relative;
  margin: 112px 120px 0;
}

.mb-112 {
  margin-bottom: 112px;
}

.founder-info {
  background-color: #1C1C1E;
  opacity: 70%;
  position: absolute;
  top: 46%;
  left: 68%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  color: #F2F2F7;
  width: 100%;
}

.founder-info {
  width: 444px;
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.founder-info p {
  width: 290px;
}

.founder-info img {
  padding: 40px 0 24px;
}

.italic {
  font-style: italic;
}

.flex-wrap {
  display: flex;
  flex-direction: column;
}

.flex-wrap span:first-child {
  padding-bottom: 8px;
}

.founder-info-row .row {
  --bs-gutter-x: 0;
}

.founder-info-img img {
  width: 100%;
}

.founder-info img.colon-top {
  position: absolute;
  top: 3%;
  left: 11%;
  padding: 0;
}

.founder-info img.colon-bottom {
  position: absolute;
  right: 36%;
  padding-top: 1%;
}

.awards{
  background-color: #FAFAFA;
  padding-bottom: 112px;
}
.works img.cafted-text-img{
    width: 50%; 
  }
.carousel-inner { 
  cursor: pointer;
}
.awards-img img{ 
  padding-right: 24px;
}
@media (max-width: 992px) {
  .works-details-img img{
    height: 311px;
  }
  .founder-info {
    padding: 24px;
    top: 50%;
  }

  .founder-info-row {
    margin: 56px 16px 0;
  }

  .mb-112 {
    margin-bottom: 56px;
  }

  .founder-info img {
    padding: 24px 0;
  }
  .awards{ 
    padding-bottom: 56px;
  }
}

@media (max-width: 576px) {
  .home-page .carousel-inner img {
    width: 100% !important;
    height: 100vh !important;
    object-fit: cover;
  }

  .home-page .carousel-caption {
    width: 100%;
    right: 0;
  }

  .home-page .carousel-caption p {
    text-align: center;

  }

  .home-page .carousel-control-next,
  .home-page .carousel-control-prev {
    bottom: 14%;
    top: unset;
  }

  .works {
    padding-bottom: 56px;
  }

  .works-details-row {
    padding: 30px;
  }

  .works-details-img {
    margin-bottom: 15px;

  }
  .works-details-img img{
    height: 230px;
  }
  .works-details-row .title-1 {
    font-size: 1.475rem;
    letter-spacing: 0.4em;
    margin-bottom: 0
  }

  .works-details-row .title-2 {
    font-size: 1rem;
    padding-left: 20px;
    letter-spacing: 3em;
  }

  .founder-info {
    position: sticky;
    transform: none;
    width: 100%;
  }

  .founder-info img.colon-top {
    top: 4%;
    left: 4%;
  }
.awards-img img{
  width: 100%;
  padding: 0 40px 56px;
}
.works img.cafted-text-img{
    width: 80%; 
  }
}
</style>
