<template>
  <div class="works-component cloud">
    <div class="banner"> 
      <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/00-hero banner.jpg" alt="">
    </div>
    <div class="border-bottom-white-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 white text-left m-0">
          云 | Cloud
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-white-1px"> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/01-landscape-dry-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/01-landscape-dry-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/01-landscape-dry-03.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/02-full-living-01.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/03-landscape-living-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/03-landscape-living-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/03-landscape-living-03.jpg" alt="">
        </b-col>
      </b-row>   
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/04-portrait-living-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/04-portrait-living-02.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/05-full-master-01.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/06-landscape-master-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/06-landscape-master-02.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/07-full-master-02.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/08-portrait-master-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/08-portrait-master-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/08-portrait-master-03.jpg" alt="">
        </b-col>
      </b-row>  
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/09-full-parent-01.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/10-landscape-parent-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/10-landscape-parent-02.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/11-portrait-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/11-portrait-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/11-portrait-03.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/12-landscape-kid-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/12-landscape-kid-02.jpg" alt="">
        </b-col>
      </b-row>   
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="Sakura"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left_white.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 white  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">樱 | Sakura</p>
              <p class="inter inter-body-md-400 white p-0">Rimbun Vista | Seremban</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 white  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">蓝色小时 | Blue</p>
              <p class="inter inter-body-md-400 white p-0">Eco Majestic Mellowood</p>
            </b-col>
            <b-col cols="3"  to="Blue"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right_white.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'cloudLinkHouse',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.cloud{
  background-color: #787773 ;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.cloud p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>