<template>
  <div class="works-component modernLuxurious1">
    <div class="banner"> 
      <img src="../../../assets/img/works/design concept-1500x1500/01-现代奢华风-MODERN LUXURIOUS-CHERAS VISTA 5-MAHKOTA CHERAS/0-EXTERIOR/1.jpg" alt="">
    </div>
    <div class="border-bottom-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 black text-left m-0">
          现代奢华风 | Modern Luxurious
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-1px"> 
      <b-row sm>
        <b-col v-for="(img, index) in images" :key="index" md="6" sm>
          <img  class="image-fixed-height" :src="require(`@/assets/img/works/design concept-1500x1500/01-现代奢华风-MODERN LUXURIOUS-CHERAS VISTA 5-MAHKOTA CHERAS/${img}`)" :alt="'Image ' + (index + 1)">
        </b-col> 
      </b-row>
       
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <!-- <b-row class="flex-center">
            <b-col cols="3" to="Perfect-In-Black"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 black  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">墨 | Perfect in Black</p>
              <p class="inter inter-body-md-400 black p-0">Wira Height Sg Long | Kajang</p>
            </b-col> 
          </b-row> -->
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 black  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">现代奢华风 | Modern Luxurious</p>
              <p class="inter inter-body-md-400 black p-0">The Netizen | Residensi Hussein Onn</p>
            </b-col>
            <b-col cols="3"  to="modern-luxurious-2"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'modernLuxurious1',
  data() {
    return { 
      images: [ 
        "0-EXTERIOR/2.jpg", 
        "0-EXTERIOR/3.jpg", 
        "0-EXTERIOR/4.jpg", 
        "0-EXTERIOR/5.jpg", 
        "0-EXTERIOR/6.jpg", 
        "0-EXTERIOR/7.jpg", 
        "0-EXTERIOR/8.jpg", 
        "0-EXTERIOR/9.jpg", 
        "0-EXTERIOR/10.jpg", 
        "0-EXTERIOR/11.jpg", 
        "0-EXTERIOR/12.jpg", 
        "0-EXTERIOR/13.jpg", 
        "0-EXTERIOR/14.jpg", 
        "0-EXTERIOR/15.jpg", 
        "0-EXTERIOR/16.jpg",  
        "0-EXTERIOR/18.jpg",   
        "1-G/1.jpg", 
        "1-G/2.jpg", 
        "1-G/3.jpg", 
        "1-G/4.jpg", 
        "1-G/5.jpg", 
        "1-G/6.jpg", 
        "1-G/7.jpg",   
        "1-G/9.jpg", 
        "1-G/10.jpg", 
        "1-G/11.jpg", 
        "1-G/12.jpg", 
        "1-G/13.jpg", 
        "1-G/14.jpg", 
        "1-G/15.jpg",  
        "1-G/16.jpg",  
        "1-G/17.jpg",  
        "1-G/18.jpg", 
        "1-G/19.jpg", 
        "1-G/20.jpg", 
        "1-G/21.jpg", 
        "1-G/22.jpg", 
        "1-G/23.jpg", 
        "1-G/24.jpg", 
        "1-G/25.jpg",  
        "1-G/26.jpg", 
        "1-G/27.jpg", 
        "1-G/28.jpg",    
        "1-G/29.jpg",    
        "1-G/30.jpg",    
        "1-G/31.jpg",    
        "1-G/32.jpg",    
        "1-G/33.jpg",    
        "2-1ST/1.jpg", 
        "2-1ST/2.jpg", 
        "2-1ST/3.jpg", 
        "2-1ST/4.jpg", 
        "2-1ST/5.jpg", 
        "2-1ST/6.jpg", 
        "2-1ST/7.jpg",   
        "2-1ST/8.jpg", 
        "2-1ST/9.jpg", 
        "2-1ST/10.jpg", 
        "2-1ST/11.jpg", 
        "2-1ST/12.jpg", 
        "2-1ST/13.jpg", 
        "2-1ST/14.jpg", 
        "2-1ST/15.jpg",  
        "2-1ST/16.jpg",  
        "2-1ST/17.jpg",  
        "2-1ST/18.jpg", 
        "3-2ND/1.jpg", 
        "3-2ND/2.jpg", 
        "3-2ND/3.jpg", 
        "3-2ND/4.jpg", 
        "3-2ND/5.jpg", 
        "3-2ND/6.jpg", 
        "3-2ND/7.jpg",   
        "3-2ND/8.jpg",   
        "3-2ND/9.jpg", 
        "3-2ND/10.jpg", 
        "3-2ND/11.jpg", 
        "3-2ND/12.jpg", 
        "3-2ND/13.jpg", 
        "3-2ND/14.jpg", 
        "3-2ND/15.jpg",  
        "3-2ND/16.jpg",  
        "3-2ND/17.jpg",  
        "3-2ND/18.jpg", 
        "3-2ND/19.jpg", 
        "3-2ND/20.jpg", 
        "3-2ND/21.jpg", 
        "3-2ND/22.jpg", 
        "3-2ND/23.jpg", 
        "1.jpg", 
        "2.jpg", 
        "3.jpg", 
        "4.jpg", 
        "5.jpg", 
        "6.jpg", 
        "7.jpg", 
        "8.jpg", 
        "9.jpg", 
        "10.jpg", 
        "11.jpg", 
        "12.jpg", 
        "13.jpg", 
        "14.jpg", 
        "15.jpg", 
        "16.jpg", 
        "17.jpg", 
        "18.jpg", 
        "19.jpg", 
        "20.jpg", 
        "21.jpg", 
        "22.jpg", 
        "23.jpg", 
        "24.jpg", 
        "25.jpg", 
        "26.jpg", 
        "27.jpg", 
        "28.jpg", 
        "29.jpg", 
        "30.jpg", 
        "31.jpg", 
        "32.jpg", 
        "33.jpg", 
        "34.jpg", 
        "35.jpg", 
        "36.jpg", 
        "37.jpg", 
        "38.jpg", 
        "39.jpg", 
        "40.jpg", 
        "41.jpg", 
        "42.jpg", 
        "43.jpg", 
        "44.jpg", 
        "45.jpg", 
        "46.jpg", 
        "47.jpg", 
        "48.jpg", 
        "49.jpg", 
        "40.jpg", 
        "51.jpg", 
        "52.jpg", 
        "53.jpg", 
        "54.jpg", 
        "55.jpg", 
        "56.jpg", 
        "57.jpg", 
        "58.jpg", 
        "59.jpg",
        "60.jpg", 
        "61.jpg", 
        "62.jpg", 
        "63.jpg", 
        "64.jpg", 
        "65.jpg", 
        "66.jpg", 
        "67.jpg", 
        "68.jpg", 
        "69.jpg", 
        "70.jpg", 
        "71.jpg", 
        "72.jpg", 
        "73.jpg", 
        "74.jpg", 
        "75.jpg", 
        "76.jpg", 
        "77.jpg", 
        "78.jpg", 
        "79.jpg", 
        "80.jpg", 
        "81.jpg", 
        "82.jpg", 
        "83.jpg", 
        "84.jpg", 
        "85.jpg", 
        "86.jpg", 
        "87.jpg", 
        "88.jpg", 
        "89.jpg", 
        "90.jpg", 
        "91.jpg", 
        "92.jpg", 
        "93.jpg", 
        "94.jpg", 
        "95.jpg", 
        "96.jpg", 
        "97.jpg", 
        "98.jpg", 
        "99.jpg", 
        "100.jpg", 
        "101.jpg", 
        "102.jpg", 
        "103.jpg", 
        "104.jpg",  
      ]
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.modernLuxurious1{
  background-color: #FFF ;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.modernLuxurious1 p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}
.image-fixed-height {
  width: 100%;  /* Ensures image takes full width of the column */
  height: 377px; /* Set a fixed height (adjust as needed) */
  object-fit: cover; /* Ensures images fill the space without distortion */
  display: block; /* Removes extra spacing below inline images */
}
@media (max-width: 576px) { 
  .image-fixed-height {
    height: auto;
  }
}
</style>