<template>
  <div> 

    <!-- Footer -->
    <footer class="primary-bg black border-bottom-1px">
      <div class="container text-left container-footer"> 
        <div class="row g-0"> 
          <div class="col-lg-4 col-md-6 col-sm-12 ">
            <h5 class="playfair playfair-title-lg-400 pb-24 ">Nine Plus Interior Design (M) Sdn Bhd</h5>
            <span class="inter inter-body-lg-400 gray-400">Our address</span>
            <p class="inter inter-body-lg-400 black">C-11-4, Medan Connaught Centre Point, Jalan 3/144A </p> 
          </div> 
          <div class="col-lg-4 col-md-6 col-sm-12 pl-8rem">
            <span class="inter inter-body-lg-400 gray-400 ">Drop us an email at</span>
            <p class="pt-0"><a href="mailto:9plusinterior@gmail.com" class="inter inter-body-lg-400 black">9plusinterior@gmail.com</a> </p>
             
            <span class="inter inter-body-lg-400 gray-400">If you need immediate assistance, call us</span>
            <p class="inter inter-body-lg-400 black">
              <a class="black" :href="'tel:+60167330321'" >+60 16 733 0321</a> - Sara
              <br>
              <a class="black" :href="'tel:+60123233282'" >+60 12 323 3282</a> - Christine</p> 
          </div> 
          <div class="col-lg-4 col-md-6 col-sm-12 pl-8rem">
            <span class="inter inter-body-lg-400 gray-400" >Follow us on social media</span>
            <ul class="list-unstyled flex-start social-media">
              <li><a href="https://www.facebook.com/9plusinterior" target="_blank"><img src="../assets/img/icon/facebook.svg" alt="Facebook"> </a></li>
              <li><a href="https://youtube.com/@nineplusinteriordesign7138" target="_blank"><img src="../assets/img/icon/youtube.svg" alt="Youtube"> </a></li>
              <li><a href="https://www.instagram.com/9plusinterior/?hl=en" target="_blank"><img src="../assets/img/icon/insta.svg" alt="instagram"> </a></li>
              <li><a href="https://wa.link/evxput" target="_blank"><img src="../assets/img/icon/whatsapp.svg" alt="whatsapp"> </a></li>
            </ul>
          </div>
        </div> 
      </div>
    </footer>
    <footer class="text-black "> 
        <div class="row g-0">
          <div class="col-12 text-center mt-5">
            <p class="inter inter-body-md-400 gray-400"> Copyright &copy; {{ currentYear }} Nine Plus Interior Design (M) Sdn Bhd (1343517-T). All right reserved.
              <br><br>
              South-East Asia | Malaysia | Kuala Lumpur</p>
          </div>
        </div> 
    </footer>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>
/* Optional: You can add more custom styles for your footer here */
footer { 
  color: #000; /* White text color */
}

footer a {
  text-decoration: none;
  transition: color 0.3s;
}

footer a:hover {
  color: #00aced; /* Twitter-like hover effect for links */
}
 
p{
  padding-top: 5px;
  padding-bottom: 40px;
  margin-bottom: 0;
}
ul{
  padding-top: 8px;
}
.pt-0{
  padding-top: 0;
}
.pl-8rem{
  padding-left: 8rem;
}
.container{
  padding: 112px 0 72px;
}

@media (max-width: 1200px) { 
  .pl-8rem{
    padding-left: 5rem;
  }
} 
@media (max-width: 992px) { 
  .pl-8rem{
    padding-left: 2rem;
  }
  .pl-8rem:last-child{
    padding-left: 0rem;
  }
} 
@media (max-width: 576px) {
  .pl-8rem{
    padding-left: 0rem;
  }
  .container{
    --bs-gutter-x : 0;
    padding: 16px;
  } 
  .container-footer{
    padding: 56px 16px;
  }
  .social-media{
    margin-bottom: 0;
  }
} 
</style>
