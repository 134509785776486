<template>
  <div class="works-component colourslinkhouse">
    <div class="banner"> 
      <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/00-hero banner.jpg" alt="">
    </div>
    <div class="border-bottom-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 black text-left m-0">
          色彩生活 | Colours
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-1px"> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/01-landscape-living-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/01-landscape-living-02.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/02-landscape-living-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/02-landscape-living-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/02-landscape-living-03.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/03-portrait-living-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/03-portrait-living-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/03-portrait-living-03.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/04-full-dry-01.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/05-landscape-dry-02.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/05-landscape-dry-02.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/06-full-wet-01.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/07-landscape-master-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/07-landscape-master-02.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/08-portrait-master-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/08-portrait-master-02.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/09-full-master-01.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/10-portrait-master-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/10-portrait-master-02.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/11-full-kid1-01.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/12-landscape-kid1-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/12-landscape-kid2-02.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/13-portrait-kid1-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/13-portrait-kid1-02.jpg" alt="">
        </b-col> 
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/13-portrait-kid1-03.jpg" alt="">
        </b-col> 
      </b-row>  
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/14-portrait-kid2-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/14-portrait-kid2-02.jpg" alt="">
        </b-col> 
      </b-row>  
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/15-full-facade-01.jpg" alt="">
        </b-col>
      </b-row>  
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="Jade"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 black  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">青禾 | Jade</p>
              <p class="inter inter-body-md-400 black p-0">Landmark Residence | Kajang</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 black  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">石 | Rock</p>
              <p class="inter inter-body-md-400 black p-0">KM1 | Bukit Jalil</p>
            </b-col>
            <b-col cols="3"  to="Rock"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'coloursLinkHouse',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.colourslinkhouse{
  background-color: #D5DAD7  !important;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.colourslinkhouse p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>