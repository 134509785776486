<template>
  <div class="works-component Perfect-In-Black ">
    <div class="banner"> 
      <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/00-hero banner.jpg" alt="">
    </div>
    <div class="border-bottom-white-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 white text-left m-0">
          墨 | Perfect in Black
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-white-1px">
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/01-landscape-ent-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/01-landscape-ent-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/02-full-liv-1.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/03-landscape-liv-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/03-landscape-liv-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/03-landscape-liv-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/04-landscape-liv-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/04-landscape-liv-2.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/05-landscape-liv-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/05-landscape-liv-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/06-full-dry-1.jpg" alt="">
        </b-col>
      </b-row>
      
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/07-landscape-dry-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/07-landscape-dry-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/08-portrait-dry-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/08-portrait-yard-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/09-full-yard-1.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/10-landscape-wet-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/10-landscape-wet-2.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/11-landscape-room1-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/11-landscape-room1-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/11-landscape-room1-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/12-portrait-room1-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/12-portrait-room1-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/13-landscape-room2-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/13-landscape-room2-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/13-landscape-room2-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/14-full-room3-1.jpg" alt="">
        </b-col>
      </b-row>  
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/15-landscape-room3-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/15-landscape-room3-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/16-landscape-room3-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/16-landscape-room3-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/16-landscape-room3-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/17-full-master-1.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/18-landscape-study-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/18-landscape-study-2.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/19-landscape-master-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/19-landscape-master-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/19-landscape-master-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/20-full-master-1.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/21-portrait-master-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/21-portrait-master-2.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/22-landscape-master-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/22-landscape-master-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/22-landscape-master-3.jpg" alt="">
        </b-col>
      </b-row> 
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="Romance-In-The-House"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left_white.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 white  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">漫乐 | Romance in the house</p>
              <p class="inter inter-body-md-400 white p-0">Eco Spring | Johor</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 white  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">沐光 | Mu</p>
              <p class="inter inter-body-md-400 white p-0">Taman Cantik | Cheras</p>
            </b-col>
            <b-col cols="3"  to="Mu"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right_white.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'perfectInBlack',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.works-component.Perfect-In-Black{
  background-color: #5A5552 !important;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.Perfect-In-Black p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>