import Vue from 'vue';  // Vue 2 import
import App from './App.vue';
import { BootstrapVue, IconsPlugin, CarouselPlugin } from 'bootstrap-vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/style/styles.css';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CarouselPlugin);

new Vue({
  render: h => h(App),
  router, // include the router
}).$mount('#app'); // mount the Vue instance
