import { render, staticRenderFns } from "./sakuraLinkHouse.vue?vue&type=template&id=60af33be"
import script from "./sakuraLinkHouse.vue?vue&type=script&lang=js"
export * from "./sakuraLinkHouse.vue?vue&type=script&lang=js"
import style0 from "./sakuraLinkHouse.vue?vue&type=style&index=0&id=60af33be&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports