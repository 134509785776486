import Vue from 'vue'
import Router from 'vue-router' 
import HomeComponent from '@/components/homeComponent.vue' 
import worksComponent from '@/components/worksComponent.vue'
import videoComponent from '@/components/videoComponent.vue'
import contactComponent from '@/components/contactComponent.vue'
import serviceComponent from '@/components/serviceComponent.vue'
import whiteWood from '@/components/works/bungalow/whiteWood.vue'
import romanceInTheHouse from '@/components/works/semi-d/romanceInTheHouse.vue'
import perfectInBlack from '@/components/works/semi-d/perfectInBlack.vue'
import guiHome from '@/components/works/semi-d/guiHome.vue'
import muGuang from '@/components/works/link-house-condo/muGuang.vue'
import comfortZone from '@/components/works/link-house-condo/comfortZone.vue'
import greySong from '@/components/works/link-house-condo/greySong.vue'
import serenityLinkHouse from '@/components/works/link-house-condo/serenityLinkHouse.vue'
import sakuraLinkHouse from '@/components/works/link-house-condo/sakuraLinkHouse.vue'
import cloud from '@/components/works/link-house-condo/cloud.vue'
import blue from '@/components/works/link-house-condo/blue.vue'
import jade from '@/components/works/link-house-condo/jade.vue'
import coloursLinkHouse from '@/components/works/link-house-condo/coloursLinkHouse.vue'
import rock from '@/components/works/link-house-condo/rock.vue'
import modernLuxurious1 from '@/components/works/design-concept/modern-luxurious-1.vue'
import notfound from '@/components/404.vue'
import ModernLuxurious2 from '@/components/works/design-concept/modern-luxurious-2.vue'
import ModernLuxurious3 from '@/components/works/design-concept/modern-luxurious-3.vue'
import ModernLuxurious4 from '@/components/works/design-concept/modern-luxurious-4.vue'
import ModernContemporary from '@/components/works/design-concept/modern-contemporary.vue'
import ModernMildLuxury from '@/components/works/design-concept/modern-mild-luxury.vue'
import ModernZen from '@/components/works/design-concept/modern-zen.vue'
import CremeInFrance from '@/components/works/design-concept/creme-in-france.vue'
import ModernLuxurious5 from '@/components/works/design-concept/modern-luxurious-5.vue'
import ModernFrench from '@/components/works/design-concept/modern-french.vue'
import MujiConcept from '@/components/works/design-concept/muji-concept.vue'
import ModernContemporary1 from '@/components/works/design-concept/modern-contemporary-1.vue'
import ModernMildLuxury2 from '@/components/works/design-concept/modern-mild-luxury-2.vue'
import RetroContactComponent from '@/components/works/design-concept/retro.vue'
import ModernContemporary2 from '@/components/works/design-concept/modern-contemporary-2.vue'
import ModernContemporary4 from '@/components/works/design-concept/modern-contemporary-4.vue'
import ModernContemporary5 from '@/components/works/design-concept/modern-contemporary-5.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeComponent,
      meta: { title: 'Interior Design Studio Cheras | Interior Design Firm Cheras | 9plusinterior Home' }
    },
    {
      path: '/Home',
      name: 'Home',
      component: HomeComponent,
      meta: { title: 'Interior Design Studio Cheras | Interior Design Firm Cheras | 9plusinterior Home' }
    },
    {
      path: '/works',
      name: 'works',
      component: worksComponent,
      meta: { title: 'Interior Design Portfolio Cheras | 9plusinterior Works' }
    },
    {
      path: '/video',
      name: 'video',
      component: videoComponent,
      meta: { title: 'Interior Design Videos Cheras | 9plusinterior Showcase' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: contactComponent,
      meta: { title: 'Interior Design Services Cheras | 9plusinterior Firm' }
    },
    {
      path: '/Services',
      name: 'Services',
      component: serviceComponent,
      meta: { title: 'Contact Interior Design Firm Cheras | 9plusinterior' }
    },
    {
      path: '/White-Wood',
      name: 'White-Wood',
      component: whiteWood
    },
    {
      path: '/Romance-In-The-House',
      name: 'Romance-In-The-House',
      component: romanceInTheHouse
    },
    {
      path: '/Perfect-In-Black',
      name: 'Perfect-In-Black',
      component: perfectInBlack
    },
    {
      path: '/Gui-Home',
      name: 'Gui-Home',
      component: guiHome
    },
    {
      path: '/Mu',
      name: 'Mu',
      component: muGuang
    },
    {
      path: '/Comfort-Zone',
      name: 'Comfort-Zone',
      component: comfortZone
    },
    {
      path: '/Grey-Song',
      name: 'Grey-Song',
      component: greySong
    },
    {
      path: '/Serenity',
      name: 'Serenity',
      component: serenityLinkHouse
    },
    {
      path: '/Sakura',
      name: 'Sakura',
      component: sakuraLinkHouse
    },
    {
      path: '/Cloud',
      name: 'Cloud',
      component: cloud
    },
    {
      path: '/Blue',
      name: 'Blue',
      component: blue
    },
    {
      path: '/Jade',
      name: 'Jade',
      component: jade
    },
    {
      path: '/Colours',
      name: 'Colours',
      component: coloursLinkHouse
    },
    {
      path: '/Rock',
      name: 'Rock',
      component: rock
    },
    {
      path: '/modern-luxurious-1',
      name: 'modern-luxurious-1',
      component: modernLuxurious1
    },
    {
      path: '/modern-luxurious-2',
      name: 'modern-luxurious-2',
      component: ModernLuxurious2
    },
    {
      path: '/modern-luxurious-3',
      name: 'modern-luxurious-3',
      component: ModernLuxurious3
    },
    {
      path: '/modern-luxurious-4',
      name: 'modern-luxurious-4',
      component: ModernLuxurious4
    },
    {
      path: '/modern-contemporary',
      name: 'modern-contemporary',
      component: ModernContemporary
    },
    {
      path: '/modern-mild-luxury',
      name: 'modern-mild-luxury',
      component: ModernMildLuxury
    },
    {
      path: '/modern-zen',
      name: 'modern-zen',
      component: ModernZen
    },
    {
      path: '/creme-in-france',
      name: 'creme-in-france',
      component: CremeInFrance
    },
    {
      path: '/modern-luxurious-5',
      name: 'modern-luxurious-5',
      component: ModernLuxurious5
    },
    {
      path: '/modern-french',
      name: 'modern-french',
      component: ModernFrench
    },
    {
      path: '/muji-concept',
      name: 'muji-concept',
      component: MujiConcept
    },
    {
      path: '/modern-contemporary-1',
      name: 'modern-contemporary-1',
      component: ModernContemporary1
    },
    {
      path: '/modern-mild-luxury-2',
      name: 'modern-mild-luxury-2',
      component: ModernMildLuxury2
    },
    {
      path: '/retro-concept',
      name: 'retro-concept',
      component: RetroContactComponent
    },
    {
      path: '/modern-contemporary-2',
      name: 'modern-contemporary-2',
      component: ModernContemporary2
    }, 
    {
      path: '/modern-contemporary-4',
      name: 'modern-contemporary-4',
      component: ModernContemporary4
    },
    {
      path: '/modern-contemporary-5',
      name: 'modern-contemporary-5',
      component: ModernContemporary5
    },
    {
      path: '/404',
      name: '404',
      component: notfound
    } ,
    {
      path: '*',
      redirect: '/'  
    }
    
  ], scrollBehavior( ) { 
    return { x: 0, y: 0 };
  },
})
 