<template>
  <div class="works-component modernLuxurious1">
    <div class="banner"> 
      <img src="../../../assets/img/works/design concept-1500x1500/14-现代简约风-MODERN CONTEMPORARY-EQUINE PARK-SERI KEMBANGAN/1.jpg" alt="">
    </div>
    <div class="border-bottom-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 black text-left m-0">
          现代简约风 | Modern Contemporary
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-1px"> 
      <b-row sm>
        <b-col v-for="(img, index) in images" :key="index" md="6" sm>
          <img  
            class="image-fixed-height"
            :src="require(`@/assets/img/works/design concept-1500x1500/14-现代简约风-MODERN CONTEMPORARY-EQUINE PARK-SERI KEMBANGAN/${img}`)" 
            :alt="'Image ' + (index + 1)"
           
          > 
          <!-- cursor-pointer @click="showLightbox(index)" -->
        </b-col> 
      </b-row>
    </div>

    <!-- Lightbox -->
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="images.map(img => require(`@/assets/img/works/design concept-1500x1500/14-现代简约风-MODERN CONTEMPORARY-EQUINE PARK-SERI KEMBANGAN/${img}`))"
      :index="currentIndex"
      @hide="lightboxVisible = false"
    />
  </div>

</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
  name: 'ModernContemporary2',
  components: { VueEasyLightbox },
  data() {
    return { 
      images: [ 
        "2.jpg", 
        "3.jpg", 
        "4.jpg", 
        "5.jpg", 
        "6.jpg", 
        "7.jpg", 
        "8.jpg", 
        "9.jpg",  
        "10.jpg",   
        "11.jpg", 
        "12.jpg", 
        "13.jpg", 
        "14.jpg", 
        "15.jpg",  
        "16.jpg", 
        "17.jpg", 
        "18.jpg", 
        "19.jpg",  
        "20.jpg",  
        "21.jpg", 
        "22.jpg", 
        "23.jpg", 
        "24.jpg", 
        "25.jpg",  
        "26.jpg", 
        "27.jpg", 
        "28.jpg", 
        "29.jpg",
        "30.jpg",  
        "31.jpg",  
        "33.jpg", 
        "34.jpg", 
        "35.jpg",  
        "36.jpg", 
        "37.jpg",
      ],
      lightboxVisible: false, 
      currentIndex: 0
    }
  },
  methods: { 
    showLightbox(index) {
      this.currentIndex = index;
      this.lightboxVisible = true;
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      }
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    }
  }, 

}
</script>

<style>
 
.modernLuxurious1{
  background-color: #FFF ;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.modernLuxurious1 p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}
.image-fixed-height {
  width: 100%;  /* Ensures image takes full width of the column */
  height: 377px; /* Set a fixed height (adjust as needed) */
  object-fit: cover; /* Ensures images fill the space without distortion */
  display: block; /* Removes extra spacing below inline images */
}
@media (max-width: 576px) { 
  .image-fixed-height {
    height: auto;
  }
}
</style>