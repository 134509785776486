<template>
  <div class="works-component pt-72">
    <div class="amalfi-coast-title amalfi-coast">Works.</div>
    <div class="noto noto-display-xl-400 gray-400  letter-spacing" >工程作品</div>
    <div class="works-tab container">
      <div class="row"  :class="{ 'fixed': isFixed  }" >
        <b-tabs v-model="activeTab" content-class="mt-3">
          <b-tab title="ALL">
            <b-row sm>
              <b-col v-for="(item, index) in works" :key="index" :to="item.link" tag="router-link" md="4" sm>
                <img :src="require(`@/assets/img/works/${item.img}`)" :alt="item.alt">
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">{{ item.title }}</div>
                  <span class="inter inter-body-md-400 gray-400">{{ item.location }}</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="BUNGALOW">
            <b-row sm>
              <b-col v-for="(item, index) in bungalowWorks" :key="index" :to="item.link" tag="router-link" md="4" sm>
                <img :src="require(`@/assets/img/works/${item.img}`)" :alt="item.alt">
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">{{ item.title }}</div>
                  <span class="inter inter-body-md-400 gray-400">{{ item.location }}</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="SEMI-D" >
            <b-row sm>
              <b-col v-for="(item, index) in semiDWorks" :key="index" :to="item.link" tag="router-link" md="4" sm>
                <img :src="require(`@/assets/img/works/${item.img}`)" :alt="item.alt">
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">{{ item.title }}</div>
                  <span class="inter inter-body-md-400 gray-400">{{ item.location }}</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="LINK HOUSE / CONDO" >
            <b-row sm>
              <b-col v-for="(item, index) in linkHouseCondoWorks" :key="index" :to="item.link" tag="router-link" md="4" sm>
                <img :src="require(`@/assets/img/works/${item.img}`)" :alt="item.alt">
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">{{ item.title }}</div>
                  <span class="inter inter-body-md-400 gray-400">{{ item.location }}</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="DESIGN CONCEPT" >
            <b-row sm>
              <b-col v-for="(item, index) in designConceptWorks" :key="index" :to="item.link" tag="router-link" md="4" sm>
                <img :src="require(`@/assets/img/works/${item.img}`)" :alt="item.alt">
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">{{ item.title }}</div>
                  <span class="inter inter-body-md-400 gray-400">{{ item.location }}</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'worksComponent',
  data() {
    return {
      isFixed: false,
      activeTab: 0,
      works: [
        { img: 'bungalow/00-hero-banner.jpg', title: '白 + 木 | White + Wood', location: 'USJ 5 | Subang Jaya', link: 'White-Wood', alt: '01-bungalow-white+wood' },
        { img: 'semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/00-hero banner.jpg', title: '归 | Home', location: 'Longbranch | Kota Kemunting', link: 'Gui-Home', alt: '04-bungalow-grey-song' },
        { img: 'semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/00-hero banner.jpg', title: '漫乐 | Romance in the house', location: 'Eco Spring | Johor', link: 'Romance-In-The-House', alt: '02-bungalow-romance-in-the-house' },
        { img: 'semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/00-hero banner.jpg', title: '墨 | Perfect in Black', location: 'Wira Height Sg Long | Kajang', link: 'Perfect-In-Black', alt: '03-bungalow-comfort' },
        
        { img: 'link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/00-hero banner.jpg', title: '沐光 | Mu', location: 'Taman Cantik | Cheras', link: 'Mu', alt: '05-bungalow-grey-song' },
        { img: 'link-house-condo/02-舒窝 -COMFORT ZONE -SUNWAY MONT- MONT KIARA/00-hero banner.jpg', title: '舒窝 | Comfort Zone', location: 'Sunway Mont | Mont Kiara', link: 'Comfort-Zone', alt: '06-bungalow-grey-song' },
        { img: 'link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/00-hero banner.jpg', title: '岩灰 | Grey Song', location: 'Kiaraville | Mont Kiara', link: 'Grey-Song', alt: '07-bungalow-grey-song' },
        { img: 'link-house-condo/04-悠-SERENITY- SUNWAY MONT -MONT KIARA/00-hero banner.jpg', title: '悠 | Serenity', location: 'Sunway Mont | Mont Kiara', link: 'Serenity', alt: '08-bungalow-grey-song' },
        { img: 'link-house-condo/05-樱 SAKURA -RIMBUN VISTA- SEREMBAN/00-hero banner.jpg', title: '樱 | Sakura', location: 'Rimbun Vista | Seremban', link: 'Sakura', alt: '09-bungalow-grey-song' },
        { img: 'link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/00-hero banner.jpg', title: '云 | Cloud', location: 'Residensi 22 | Mont Kiara', link: 'Cloud', alt: '10-bungalow-grey-song' },
        { img: 'link-house-condo/07-蓝色小时-BLU-ECO MAJESTIC MELLOWOOD- SEMENYIH/00-hero banner.jpg', title: '蓝色小时 | Blue', location: 'Eco Majestic Mellowood', link: 'Blue', alt: '11-bungalow-grey-song' },
        { img: 'link-house-condo/08-青禾 JADE- LANDMARK RESIDENCE-KAJANG/00-hero banner.jpg', title: '青禾 | Jade', location: 'Landmark Residence | Kajang', link: 'Jade', alt: '12-bungalow-grey-song' },
        { img: 'link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/00-hero banner.jpg', title: '色彩生活 | Colours', location: 'Semanja Kajang | Kajang', link: 'Colours', alt: '13-bungalow-grey-song' },
        { img: 'link-house-condo/10-石-ROCK-KM1-BUKIT JALIL/00-hero banner.jpg', title: '石 | Rock', location: 'KM1 | Bukit Jalil', link: 'Rock', alt: '14-bungalow-grey-song' },
        { img: 'design concept-1500x1500/01-现代奢华风-MODERN LUXURIOUS-CHERAS VISTA 5-MAHKOTA CHERAS/0-EXTERIOR/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'Cheras Vista 5 | Mahkota Cheras', link: 'modern-luxurious-1', alt: 'Cheras Vista 5 | Mahkota Cheras' }, 
        { img: 'design concept-1500x1500/02-现代奢华风-MODERN LUXURIOUS-THE NETIZEN-RESIDENSI HUSSEIN ONN/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'The Netizen | Residensi Hussein Onn', link: 'modern-luxurious-2', alt: 'The Netizen | Residensi Hussein Onn' },
        { img: 'design concept-1500x1500/03-现代奢华风-MODERN LUXURIOUS-JADE HILLS-KAJANG/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'Jade Hills | Kajang', link: 'modern-luxurious-3', alt: 'Jade Hills | Kajang' },
        { img: 'design concept-1500x1500/04-现代奢华风-MODERN LUXURIOUS-THE ESTATE-BANGSAR SOUTH/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'The Estate | Bangsar South', link: 'modern-luxurious-4', alt: 'The Estate | Bangsar South' }, 
        { img: 'design concept-1500x1500/05-现代简约风-MODERN CONTEMPORARY-SEGAMAT-JOHOR/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Segamat | Johor', link: 'modern-contemporary', alt: 'Segamat | Johor' }, 
        { img: 'design concept-1500x1500/06-现代轻奢风-MODERN MILD LUXURY-CHERAS VISTA 4-MAHKOTA CHERAS/1.jpg', title: '现代轻奢风 | Modern Mild Luxury', location: 'Cheras Vista 4 | Mahkota Cheras', link: 'modern-mild-luxury', alt: 'Cheras Vista 4 | Mahkota Cheras' }, 
        { img: 'design concept-1500x1500/07-现代禅风-MODERN ZEN-IDAMAN VILLA-BATU 9/1.jpg', title: '现代禅风 | Modern Zen', location: 'Idaman Villa | Batu 9', link: 'modern-zen', alt: 'Idaman Villa | Batu 9' }, 
        { img: 'design concept-1500x1500/08-奶油法式风-CREME IN FRANCE-SAVILLE THE PARK TWIN VILLA-BANGSAR SOUTH/1.jpg', title: '奶油法式风 | Creme In France', location: 'Saville The Park Twin Villa | Bangsar South', link: 'creme-in-france', alt: 'Saville The Park Twin Villa | Bangsar South' }, 
        { img: 'design concept-1500x1500/09-现代奢华风-MODERN LUXURIOUS-CHERAS VISTA 4-MAHKOTA CHERAS/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'Cheras Vista 4 | Mahkota Cheras', link: 'modern-luxurious-5',  alt: 'Cheras Vista 4 | Mahkota Cheras' }, 
        { img: 'design concept-1500x1500/10-现代法式风-MODERN FRENCH-RYMBA HILLS-KOTA DAMANSARA/1.jpg', title: '现代法式风 | Modern French', location: 'Rymba Hills | Kota Damansara', link: 'modern-french', alt: 'Rymba Hills | Kota Damansara' }, 
        { img: 'design concept-1500x1500/11-简约风-MUJI CONCEPT-LAVENA GAMUDA GARDENS-RAWANG/1.jpg', title: '简约风 | Muji Concept', location: 'Lavena Gamuda Gardens | Rawang', link: 'muji-concept', alt: 'Lavena Gamuda Gardens | Rawang' }, 
        { img: 'design concept-1500x1500/12-现代简约风-MODERN CONTEMPORARY-ECO MAJESTIC TENDERFIELDS-SEMENYIH/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Eco Majestic Tenderfields | Semenyih', link: 'modern-contemporary-1', alt: 'Eco Majestic Tenderfields | Semenyih' }, 
        { img: 'design concept-1500x1500/13-现代轻奢风-MODERN MILD LUXURY-ECO MONTEREY-KOTA KEMUNING/1.jpg', title: '现代轻奢风 | Modern Mild Luxury', location: 'Eco Monterey | Kota Kemuning', link: 'modern-mild-luxury-2', alt: 'Eco Monterey | Kota Kemuning' }, 
        { img: 'design concept-1500x1500/14-现代简约风-MODERN CONTEMPORARY-EQUINE PARK-SERI KEMBANGAN/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Equine Park | Seri Kembangan', link: 'modern-contemporary-2', alt: 'Equine Park | Seri Kembangan' }, 
        { img: 'design concept-1500x1500/15-复古怀旧风-RETRO-JALAN GASING-PETALING JAYA/1.jpg', title: '复古怀旧风 | Retro', location: 'Jalan Gasing | Petaling Jaya', link: 'retro-concept', alt: 'Jalan Gasing | Petaling Jaya' }, 
        { img: 'design concept-1500x1500/16-现代简约风-MODERN CONTEMPORARY-ARMANEE TERRENCE-DAMANSARA PERDANA/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Armanee Terrence | Damansara Perdana', link: 'modern-contemporary-4', alt: 'Armanee Terrence | Damansara Perdana' }, 
        { img: 'design concept-1500x1500/17-现代简约风-MODERN CONTEMPORARY-SKYZ RESIDENCE-PUCHONG/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Skyz Residence | Puchong', link: 'modern-contemporary-5', alt: 'Skyz Residence | Puchong' }, 

      ],
      bungalowWorks: [
        { img: 'bungalow/00-hero-banner.jpg', title: '白 + 木 | White + Wood', location: 'USJ 5 | Subang Jaya', link: 'White-Wood', alt: '01-bungalow-white+wood' }, 
        { img: 'semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/00-hero banner.jpg', title: '归 | Home', location: 'Longbranch | Kota Kemunting', link: 'Gui-Home', alt: '04-bungalow-grey-song' },
      ],
      semiDWorks:[
        { img: 'semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/00-hero banner.jpg', title: '漫乐 | Romance in the house', location: 'Eco Spring | Johor', link: 'Romance-In-The-House', alt: '02-bungalow-romance-in-the-house' },
        { img: 'semi-d/02-墨- PERFECT IN BLACK- WIRA HEIGHT SG LONG- KAJANG/00-hero banner.jpg', title: '墨 | Perfect in Black', location: 'Wira Height Sg Long | Kajang', link: 'Perfect-In-Black', alt: '03-bungalow-comfort' },
      ],
      linkHouseCondoWorks:[
        { img: 'link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/00-hero banner.jpg', title: '沐光 | Mu', location: 'Taman Cantik | Cheras', link: 'Mu', alt: '05-bungalow-grey-song' },
        { img: 'link-house-condo/02-舒窝 -COMFORT ZONE -SUNWAY MONT- MONT KIARA/00-hero banner.jpg', title: '舒窝 | Comfort Zone', location: 'Sunway Mont | Mont Kiara', link: 'Comfort-Zone', alt: '06-bungalow-grey-song' },
        { img: 'link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/00-hero banner.jpg', title: '岩灰 | Grey Song', location: 'Kiaraville | Mont Kiara', link: 'Grey-Song', alt: '07-bungalow-grey-song' },
        { img: 'link-house-condo/04-悠-SERENITY- SUNWAY MONT -MONT KIARA/00-hero banner.jpg', title: '悠 | Serenity', location: 'Sunway Mont | Mont Kiara', link: 'Serenity', alt: '08-bungalow-grey-song' },
        { img: 'link-house-condo/05-樱 SAKURA -RIMBUN VISTA- SEREMBAN/00-hero banner.jpg', title: '樱 | Sakura', location: 'Rimbun Vista | Seremban', link: 'Sakura', alt: '09-bungalow-grey-song' },
        { img: 'link-house-condo/06-云-CLOUD-RESIDENSI 22-MONT KIARA/00-hero banner.jpg', title: '云 | Cloud', location: 'Residensi 22 | Mont Kiara', link: 'Cloud', alt: '10-bungalow-grey-song' },
        { img: 'link-house-condo/07-蓝色小时-BLU-ECO MAJESTIC MELLOWOOD- SEMENYIH/00-hero banner.jpg', title: '蓝色小时 | Blue', location: 'Eco Majestic Mellowood', link: 'Blue', alt: '11-bungalow-grey-song' },
        { img: 'link-house-condo/08-青禾 JADE- LANDMARK RESIDENCE-KAJANG/00-hero banner.jpg', title: '青禾 | Jade', location: 'Landmark Residence | Kajang', link: 'Jade', alt: '12-bungalow-grey-song' },
        { img: 'link-house-condo/09-色彩生活-COLOURS- SEMANJA KAJANG -KAJANG/00-hero banner.jpg', title: '色彩生活 | Colours', location: 'Semanja Kajang | Kajang', link: 'Colours', alt: '13-bungalow-grey-song' },
        { img: 'link-house-condo/10-石-ROCK-KM1-BUKIT JALIL/00-hero banner.jpg', title: '石 | Rock', location: 'KM1 | Bukit Jalil', link: 'Rock', alt: '14-bungalow-grey-song' }
      ],
      designConceptWorks:[
        { img: 'design concept-1500x1500/01-现代奢华风-MODERN LUXURIOUS-CHERAS VISTA 5-MAHKOTA CHERAS/0-EXTERIOR/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'Cheras Vista 5 | Mahkota Cheras', link: 'modern-luxurious-1', alt: 'Cheras Vista 5 | Mahkota Cheras' }, 
        { img: 'design concept-1500x1500/02-现代奢华风-MODERN LUXURIOUS-THE NETIZEN-RESIDENSI HUSSEIN ONN/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'The Netizen | Residensi Hussein Onn', link: 'modern-luxurious-2', alt: 'The Netizen | Residensi Hussein Onn' },
        { img: 'design concept-1500x1500/03-现代奢华风-MODERN LUXURIOUS-JADE HILLS-KAJANG/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'Jade Hills | Kajang', link: 'modern-luxurious-3', alt: 'Jade Hills | Kajang' },
        { img: 'design concept-1500x1500/04-现代奢华风-MODERN LUXURIOUS-THE ESTATE-BANGSAR SOUTH/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'The Estate | Bangsar South', link: 'modern-luxurious-4', alt: 'The Estate | Bangsar South' }, 
        { img: 'design concept-1500x1500/05-现代简约风-MODERN CONTEMPORARY-SEGAMAT-JOHOR/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Segamat | Johor', link: 'modern-contemporary', alt: 'Segamat | Johor' }, 
        { img: 'design concept-1500x1500/06-现代轻奢风-MODERN MILD LUXURY-CHERAS VISTA 4-MAHKOTA CHERAS/1.jpg', title: '现代轻奢风 | Modern Mild Luxury', location: 'Cheras Vista 4 | Mahkota Cheras', link: 'modern-mild-luxury', alt: 'Cheras Vista 4 | Mahkota Cheras' }, 
        { img: 'design concept-1500x1500/07-现代禅风-MODERN ZEN-IDAMAN VILLA-BATU 9/1.jpg', title: '现代禅风 | Modern Zen', location: 'Idaman Villa | Batu 9', link: 'modern-zen', alt: 'Idaman Villa | Batu 9' }, 
        { img: 'design concept-1500x1500/08-奶油法式风-CREME IN FRANCE-SAVILLE THE PARK TWIN VILLA-BANGSAR SOUTH/1.jpg', title: '奶油法式风 | Creme In France', location: 'Saville The Park Twin Villa | Bangsar South', link: 'creme-in-france', alt: 'Saville The Park Twin Villa | Bangsar South' }, 
        { img: 'design concept-1500x1500/09-现代奢华风-MODERN LUXURIOUS-CHERAS VISTA 4-MAHKOTA CHERAS/1.jpg', title: '现代奢华风 | Modern Luxurious', location: 'Cheras Vista 4 | Mahkota Cheras', link: 'modern-luxurious-5',  alt: 'Cheras Vista 4 | Mahkota Cheras' }, 
        { img: 'design concept-1500x1500/10-现代法式风-MODERN FRENCH-RYMBA HILLS-KOTA DAMANSARA/1.jpg', title: '现代法式风 | Modern French', location: 'Rymba Hills | Kota Damansara', link: 'modern-french', alt: 'Rymba Hills | Kota Damansara' }, 
        { img: 'design concept-1500x1500/11-简约风-MUJI CONCEPT-LAVENA GAMUDA GARDENS-RAWANG/1.jpg', title: '简约风 | Muji Concept', location: 'Lavena Gamuda Gardens | Rawang', link: 'muji-concept', alt: 'Lavena Gamuda Gardens | Rawang' }, 
        { img: 'design concept-1500x1500/12-现代简约风-MODERN CONTEMPORARY-ECO MAJESTIC TENDERFIELDS-SEMENYIH/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Eco Majestic Tenderfields | Semenyih', link: 'modern-contemporary-1', alt: 'Eco Majestic Tenderfields | Semenyih' }, 
        { img: 'design concept-1500x1500/13-现代轻奢风-MODERN MILD LUXURY-ECO MONTEREY-KOTA KEMUNING/1.jpg', title: '现代轻奢风 | Modern Mild Luxury', location: 'Eco Monterey | Kota Kemuning', link: 'modern-mild-luxury-2', alt: 'Eco Monterey | Kota Kemuning' }, 
        { img: 'design concept-1500x1500/14-现代简约风-MODERN CONTEMPORARY-EQUINE PARK-SERI KEMBANGAN/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Equine Park | Seri Kembangan', link: 'modern-contemporary-2', alt: 'Equine Park | Seri Kembangan' }, 
        { img: 'design concept-1500x1500/15-复古怀旧风-RETRO-JALAN GASING-PETALING JAYA/1.jpg', title: '复古怀旧风 | Retro', location: 'Jalan Gasing | Petaling Jaya', link: 'retro-concept', alt: 'Jalan Gasing | Petaling Jaya' }, 
        { img: 'design concept-1500x1500/16-现代简约风-MODERN CONTEMPORARY-ARMANEE TERRENCE-DAMANSARA PERDANA/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Armanee Terrence | Damansara Perdana', link: 'modern-contemporary-4', alt: 'Armanee Terrence | Damansara Perdana' }, 
        { img: 'design concept-1500x1500/17-现代简约风-MODERN CONTEMPORARY-SKYZ RESIDENCE-PUCHONG/1.jpg', title: '现代简约风 | Modern Contemporary', location: 'Skyz Residence | Puchong', link: 'modern-contemporary-5', alt: 'Skyz Residence | Puchong' }, 

      ]
    }
  },
  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newTab) {
        const tabMap = {
          all: 0,
          bungalow: 1,
          semiD: 2,
          linkHouseCondo: 3,
          designConcept: 4,
        };
        this.activeTab = tabMap[newTab] || 0;
      },
    },
  },
  methods: {
    handleScroll() {
      const navTabs = document.querySelector('.nav-tabs');
      if (window.scrollY > navTabs.offsetTop) { 
        this.isFixed = true;
      } else { 
        this.isFixed = false;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

}
</script>

<style>
.pt-72 {
  padding-top: 72px;
}

.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}

.nav {
  justify-content: center;
}

.works-component .nav-link {
  width: 210px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8E8E93;
  padding: 32px 0;
}
.works-component .nav-tabs {
  margin-bottom: 56px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: 2px solid black !important;
  border: 0;
  color: black; 
}

.fixed .nav-tabs {
  position: fixed;
  /* When scrolled, this will make it fixed */
  top: 72px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  
}

.works-tab {
  padding-bottom: 56px;
}
.works-tab img{
  width: 100%;
  height: 256px; 
  object-fit: cover;
}
.works-tab .row .col {
  padding-top: 56px;

}

img {
  width: 100%
}
a{
  text-decoration: none !important;
}
@media (max-width: 992px) {
  .works-component img {
        width: 100%;
    }
}
@media (max-width: 576px) {
  .works-component .nav-link {
    width: 114px;
    padding: 16px 0 16px;
  }

  .works-component .nav-item:nth-child(1) .nav-link,
  .works-component .nav-item:nth-child(2) .nav-link,
  .works-component .nav-item:nth-child(3) .nav-link {
    border-bottom: 1px solid #dee2e6;
  }

  .works-component .nav-item:nth-child(4) .nav-link,
  .works-component .nav-item:nth-child(5) .nav-link {
    width: 132px;
  }

  .nav-tabs .nav-item:nth-child(4).show .nav-link,
  .nav-tabs .nav-item:nth-child(4) .nav-link.active,
  .nav-tabs .nav-item:nth-child(5).show .nav-link,
  .nav-tabs .nav-item:nth-child(5) .nav-link.active {
    border-top: 1px solid #dee2e6 !important;
  }

  .works-component img {
        width: 100%;
    }
}
</style>