<template>
  <div class="works-component guiHome ">
    <div class="banner"> 
      <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/00-hero banner.jpg" alt="">
    </div>
    <div class="border-bottom-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 black text-left m-0">
          归 | Home
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-1px">
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/01-landscape-liv-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING//01-landscape-liv-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING//01-landscape-liv-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/02-portrait-liv-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/02-portrait-liv-2.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/03-full-liv-1.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/04-portrait-liv-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/04-portrait-liv-2.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/05-landscape-dry-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/05-landscape-dry-2.jpg" alt="">
        </b-col> 
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/05-landscape-dry-3.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col >
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/06-full-master-1.jpg" alt="">
        </b-col> 
      </b-row>  
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/07-landscape-master-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/07-landscape-master-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/07-landscape-master-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/08-portrait-master-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/08-portrait-master-2.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/09-landscape-guest-2.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/09-landscape-guest-3.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/03-归-HOME-LONGBRANCH-KOTA KEMUNTING/10-landscape-master-1 1.jpg" alt="">
        </b-col>
      </b-row>   
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="White-Wood"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 black  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">白 + 木 | WhiteWood</p>
              <p class="inter inter-body-md-400 black p-0">USJ 5 | Subang Jaya</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 black  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">漫乐 | Romance in the house</p>
              <p class="inter inter-body-md-400 black p-0">Eco Spring | Johor</p>
            </b-col>
            <b-col cols="3" to="Romance-In-The-House"  tag="router-link" ><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right.svg"></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'guiHome',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.guiHome{
  background-color: #EFEFE7 ;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.guiHome p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>