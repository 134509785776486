<template>
  <div class="works-component notfound-component pt-72">
    <div class="amalfi-coast-title amalfi-coast">Page not found.</div>
    <div class="noto noto-display-xl-400 gray-400  letter-spacing" >找不到页面</div> 
    <p class="inter inter-title-sm-400 gray-400">It look like the page you're looking for doesn't exist. But don't worry, we're here to help you get back on track.</p>
    <b-button variant="dark" class="inter inter-body-lg-600" to="home"  tag="router-link" >Get Back Home<img
      src="../assets/img/icon/arrow_forward.svg" alt=""></b-button> 
  </div>
</template>

<script>
export default {
  name: 'notfoundComponent',
  data() {
    return {
      isFixed: false, 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
.pt-72 {
  padding-top: 72px;
}

.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}

.nav {
  justify-content: center;
}

.works-component .nav-link {
  width: 210px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8E8E93;
  padding: 32px 0;
}
.works-component .nav-tabs {
  margin-bottom: 56px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: 2px solid black !important;
  border: 0;
  color: black; 
}

.fixed .nav-tabs {
  position: fixed;
  /* When scrolled, this will make it fixed */
  top: 72px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  
}

.works-tab {
  padding-bottom: 56px;
}
.works-tab img{
  width: 100%;
  height: 256px; 
  object-fit: cover;
}
.works-tab .row .col {
  padding-top: 56px;

}

img {
  width: 100%
}
a{
  text-decoration: none !important;
}
.notfound-component .btn{
  border-radius: unset ;
  padding: 16px;
}
.notfound-component{
  padding-bottom: 56px ;
}
.notfound-component .btn img {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}
.notfound-component p{
  width: 588px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 24px;
}
@media (max-width: 992px) {
  .works-component img {
        width: 100%;
    }
}
@media (max-width: 576px) {
  .notfound-component p{
  width: 100%;
  text-align: center;
}
  .works-component .nav-link {
    width: 114px;
    padding: 16px 0 16px;
  }

  .works-component .nav-item:nth-child(1) .nav-link,
  .works-component .nav-item:nth-child(2) .nav-link,
  .works-component .nav-item:nth-child(3) .nav-link {
    border-bottom: 1px solid #dee2e6;
  }

  .works-component .nav-item:nth-child(4) .nav-link,
  .works-component .nav-item:nth-child(5) .nav-link {
    width: 132px;
  }

  .nav-tabs .nav-item:nth-child(4).show .nav-link,
  .nav-tabs .nav-item:nth-child(4) .nav-link.active,
  .nav-tabs .nav-item:nth-child(5).show .nav-link,
  .nav-tabs .nav-item:nth-child(5) .nav-link.active {
    border-top: 1px solid #dee2e6 !important;
  }

  .works-component img {
        width: 100%;
    }
}
</style>