

<template>
  
  <div id="app"> 
    <navComponent></navComponent> 
    <router-view></router-view> 
    <FooterComponent></FooterComponent> 
  </div>
</template>

<script> 
import navComponent from './components/navComponent.vue';
import FooterComponent from './components/footerComponent.vue'; 
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld, 
    navComponent, 
    FooterComponent
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
