<template>
    <div class="service-component ">
        <div class="amalfi-coast-title amalfi-coast">
            Services.
        </div>
        <div class="noto noto-display-xl-400 gray-400 letter-spacing">服务项目</div>
        <div class="container">
            <div class="en-title">
                <span class="playfair playfair-title-md-400  pd-56">Interior Design</span>
            </div>
            <div class="cn-title">
                <span class="inter inter-title-sm-400 gray-400">室内设计</span>
            </div>
            <div class="vertical-steps">
                <div class="row">
                    <div class=" col-md-4 col-3 flex-end pd-56">
                        <div class="vertical-title"> <span class=" noto noto-body-lg-600  pd-56">专<br>案<br>前<br>期</span>
                            <span class=" inter inter-body-lg-400">Early Phase</span>
                        </div>
                    </div>
                    <ul class="col-md-8 col-9 list-group">
                        <li v-for="(step, index) in steps" :key="index" class="list-group-item "
                            :class="{ 'active-step': currentStep === index, 'completed-step': currentStep > index }">

                            <div class="step-title inter inter-body-lg-400">
                                {{ step.title }}
                            </div>
                            <div class="step-circle-container">
                                <div class="step-circle mx-3">
                                    <span></span>
                                </div>
                            </div>
                            <div class="step-description ">
                                <div>
                                    <p class="mb-1 noto noto-body-lg-600 black">{{ step.description.zhTitle }}</p>
                                    <p class="mb-1 noto noto-body-lg-400 text-with-linebreaks gray-400">{{
                                        step.description.zh }}</p>
                                    <p class="mb-1 inter inter-body-lg-400 black font-weight-600">{{
                                        step.description.enTitle }}</p> <!-- English -->
                                    <p class="inter inter-body-lg-400 gray-400">{{ step.description.en }}</p>
                                    <!-- Chinese -->
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="vertical-steps vertical-steps2">
                <div class="row">
                    <div class=" col-md-4 col-3 flex-end pd-56">
                        <div class="vertical-title  "> <span
                                class=" noto noto-body-lg-600  pd-56">专<br>案<br>中<br>期</span> <span
                                class=" inter inter-body-lg-400">Mid Phase</span> </div>
                    </div>
                    <ul class="col-md-8 col-9 list-group">
                        <li v-for="(step, index) in steps2" :key="index" class="list-group-item "
                            :class="{ 'active-step': currentStep === index, 'completed-step': currentStep > index }">

                            <div class="step-title inter inter-body-lg-400">
                                {{ step.title }}
                            </div>
                            <div class="step-circle-container">
                                <div class="step-circle mx-3">
                                    <span></span>
                                </div>
                            </div>
                            <div class="step-description ">
                                <div>
                                    <p class="mb-1 noto noto-body-lg-600 black">{{ step.description.zhTitle }}</p>
                                    <p class="mb-1 noto noto-body-lg-400 text-with-linebreaks gray-400">{{
                                        step.description.zh }}</p>
                                    <p class="mb-1 inter inter-body-lg-400 black font-weight-600">{{
                                        step.description.enTitle }}</p> <!-- English -->
                                    <p class="inter inter-body-lg-400 gray-400">{{ step.description.en }}</p>
                                    <!-- Chinese -->
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="vertical-steps vertical-steps3">
                <div class="row">
                    <div class=" col-md-4 col-3 flex-end pd-56">
                        <div class="vertical-title"> <span class=" noto noto-body-lg-600  pd-56">专<br>案<br>后<br>期</span>
                            <span class=" inter inter-body-lg-400">Final Phase</span>
                        </div>
                    </div>
                    <ul class="col-md-8 col-9 list-group">
                        <li v-for="(step, index) in steps3" :key="index" class="list-group-item "
                            :class="{ 'active-step': currentStep === index, 'completed-step': currentStep > index }">

                            <div class="step-title inter inter-body-lg-400">
                                {{ step.title }}
                            </div>
                            <div class="step-circle-container">
                                <div class="step-circle mx-3">
                                    <span></span>
                                </div>
                            </div>
                            <div class="step-description ">
                                <div>
                                    <p class="mb-1 noto noto-body-lg-600 black">{{ step.description.zhTitle }}</p>
                                    <p class="mb-1 noto noto-body-lg-400 text-with-linebreaks gray-400">{{
                                        step.description.zh }}</p>
                                    <p class="mb-1 inter inter-body-lg-400 black font-weight-600">{{
                                        step.description.enTitle }}</p> <!-- English -->
                                    <p class="inter inter-body-lg-400 gray-400">{{ step.description.en }}</p>
                                    <!-- Chinese -->
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: 'serviceComponent',
    data() {
        return {
            currentStep: 0, // Current active step
            steps: [
                {
                    title: 'Step 1',
                    description: {
                        enTitle: 'Personalized Consultation',
                        en: 'Discussion of client needs and core design concept provision of the initial design proposal',
                        zhTitle: '专案咨询',
                        zh: '空间需求讨论，\n初步建议',
                    },
                },
                {
                    title: 'Step 2',
                    description: {
                        enTitle: 'Agreement',
                        en: 'Confirmation and signing of design contract Initiation of interior design works and spatial planning',
                        zhTitle: '设计签约',
                        zh: '签订设计合约后，\n开始进行室内设计规划',
                    },
                },
                {
                    title: 'Step 3',
                    description: {
                        enTitle: 'Site Assessment',
                        en: 'Visitation of site, measurement of structural build mapping of space core structure, facilities, existing electric and water outlets',
                        zhTitle: '工地测绘',
                        zh: '空间格局，设备，水电位置 \n实地丈量',
                    },
                }
            ],
            steps2: [
                {
                    title: 'Step 4',
                    description: {
                        enTitle: 'Furniture Layout Plan & Mood Board',
                        en: 'The formal proposal of the interior design concept and initial drawing highlight of core concepts and realign with client interests/preference',
                        zhTitle: '平面图研',
                        zh: '室内设计概念意象提案，\n点亮设计构思',
                    },
                },
                {
                    title: 'Step 5',
                    description: {
                        enTitle: '3D Artist Impression Drawing',
                        en: 'Conception and presentation of 3D drawings to the clients',
                        zhTitle: '3D图绘制',
                        zh: '立体示意室内设计稿',
                    },
                },
                {
                    title: 'Step 6',
                    description: {
                        enTitle: 'Construction Drawing',
                        en: 'Presentation of renovation plans to the client',
                        zhTitle: '工程图绘制',
                        zh: '室内设计施工图说',
                    },
                }
            ],
            steps3: [
                {
                    title: 'Step 7',
                    description: {
                        enTitle: 'Price Esimation',
                        en: 'Liaison with contractors/suppliers to estimate the required cost to achieve the proposed interior design. Formal proposal of renovation cost to client',
                        zhTitle: '工程估价',
                        zh: '协助与厂商及各工种，\n沟通图面后，果整报价',
                    },
                },
                {
                    title: 'Step 8',
                    description: {
                        enTitle: 'Signing of Renovation Contract',
                        en: 'Official signing and confirmation of renovation works. Initiation of renovation works',
                        zhTitle: '工程签约',
                        zh: '签订工程合约后, \n开始施工工程',
                    },
                },
                {
                    title: 'Step 9',
                    description: {
                        enTitle: 'Furniture Detailing Drawing',
                        en: 'Final material selection, size, and detailing',
                        zhTitle: '家私制造图绘制',
                        zh: '材料选择, \n家私施工图说',
                    },
                },
                {
                    title: 'Step 10',
                    description: {
                        enTitle: 'Renovation Phase',
                        en: 'Regular site inspection on ongoing renovation. Ensure renovation can deliver earlier-proposed interior design outcome',
                        zhTitle: '施工',
                        zh: '定期现场建造, \n协助现场沟通释疑',
                    },
                },
                {
                    title: 'Step 11',
                    description: {
                        enTitle: 'Handover',
                        en: 'Official handing over of space to client Complimentary photography of final outcome for the designed space',
                        zhTitle: '交屋',
                        zh: '可协助安排空间摄影',
                    },
                }
            ],
        }
    },
    methods: {
    }

}
</script>

<style>
.en-title {
    padding: 56px 0;
}

.cn-title {
    display: inline-block; 
    position: relative; 
    padding-bottom: 100px;
    width: 100%;
}

.cn-title span {
    display: inline-block; 
    position: relative; 
    width: 15%;
    background-color: #fff;
}

.cn-title::after {
    content: '';
    position: absolute;
    bottom: 0; 
    left: 0;
    top: 11px;
    width: 100%; 
    height: 2px; 
    background-color: #E5E5EA; 
    z-index: -1;
}

.service-component {
    padding-top: 72px;
    padding-bottom: 56px;
}

.vertical-steps .list-group {
    flex-wrap: wrap;
    flex-direction: row;
}

.vertical-steps .list-group-item:first-child {
    padding: 68px 24px 56px;
}

.vertical-steps .list-group-item {
    border: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px 56px;
}

.step-title,
.step-circle-container,
.step-description {
    display: flex; 
    align-items: center; 
}

.vertical-title {
    color: #fff;
    background-color: #C6BAAE;
    height: 789px;
    width: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
}

.vertical-steps2 .vertical-title {
    background-color: #808080;
}

.vertical-steps2 .step-title {
    color: #808080;
}

.vertical-steps3 .vertical-title {
    background-color: #928074;
    height: 1483px;
}

.vertical-steps3 .step-title {
    color: #928074;
}

.step-circle {
    width: 30px;
    height: 30px;
    background-color: #C6BAAE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C6BAAE;
    font-weight: bold;
}

.active-step .step-circle {
    background-color: #C6BAAE; 
}

.step-title {
    font-weight: bold;
    color: #C6BAAE;
    width: 66px;
}

.step-description {
    text-align: left;
    width: 320px;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.description-title {
    font-size: 0.85rem;
    color: #C6BAAE;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.text-muted {
    color: #C6BAAE !important;
}



.completed-step .step-title,
.completed-step .step-description,
.completed-step .description-title {
    color: #C6BAAE; 
}

.text-with-linebreaks {
    white-space: pre-wrap;
    padding-bottom: 24px; 
}

.align-items-center {
    justify-content: center;
}

.step-circle-container {
    position: relative;
}

.step-circle {
    width: 6px;
    height: 6px;
    background-color: #C6BAAE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C6BAAE;
    font-weight: bold;
    position: relative;
}

.step-circle-container {
    padding: 0 1.5em 1.5em 1.5em;
    position: relative;
}

.step-circle-container::before {
    content: '';
    position: absolute;
    top: -118px;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    background-color: #E5E5EA;
    z-index: 0;
    height: 405px
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 992px) {
    .step-description {
        width: 230px;
    }

}

@media (max-width: 576px) {
    .step-description {
        width: 180px;
    }

    .step-circle-container {
        padding: 0;
        position: relative;
    }

    .vertical-title {
        width: 74px;
        height: 1000px;
    }

    .vertical-steps .list-group-item {
        padding: 0 5px 56px;
    }

    .vertical-steps .list-group-item:first-child {
        padding: 50px 5px 56px;
    }

    .step-title {
        width: 37px;
    }

    .cn-title span {
        width: 97px;
    }

    .inter-body-lg-400 {
        font-size: 12px;
    } 
    .step-circle-container::before {
        height: 430px;
    }
}
</style>