<template>
  <div class="works-component Romance-In-The-House ">
    <div class="banner"> 
      <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/00-hero banner.jpg" alt="">
    </div>
    <div class="border-bottom-white-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 white text-left m-0">
          漫乐 | Romance in the house
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-white-1px">
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/01-landscape-entrance-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/01-landscape-entrance-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/02-landscape-entrance-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/02-landscape-entrance-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/02-landscape-entrance-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/03-full-living-1.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/04-landscape-living-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/04-landscape-living-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/04-landscape-living-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/05-portrait-living-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/05-portrait-living-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/06-full-dining-1.jpg" alt="">
        </b-col>
      </b-row>
      
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/07-portrait-dining-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/07-portrait-dining-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/08-landscape-wet-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/08-landscape-wet-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/09-portrait-wet-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/09-portrait-wet-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/09-portrait-wet-3.jpg" alt="">
        </b-col>
      </b-row>
      
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/10-full-master-1.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/11-landscape-master-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/11-landscape-master-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/11-landscape-master-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/12-portrait-master-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/12-portrait-master-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/12-portrait-master-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/semi-d/01-漫乐- Romance in the house -ECO SPRING-JOHOR/13-full-guest-1.jpg" alt="">
        </b-col>
      </b-row>  
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="Gui-Home" tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left_white.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 white  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">归 | Home</p>
              <p class="inter inter-body-md-400 white p-0">Longbranch | Kota Kemunting</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 white  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">墨 | Perfect in Black</p>
              <p class="inter inter-body-md-400 white p-0">Wira Height Sg Long | Kajang</p>
            </b-col>
            <b-col cols="3"  to="Perfect-In-Black"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right_white.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'romanceInTheHouse',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.Romance-In-The-House{
  background-color: #A5A7A6;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.Romance-In-The-House p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>