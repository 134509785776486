<template>
  <div class="contact-component ">
    <div class="amalfi-coast-title amalfi-coast"> 
      <img src="../assets/img/contact.png" class="contact-text-img"  alt="">
    </div>
    <div class="noto noto-display-xl-400 gray-400 letter-spacing">联络我们</div>
    <div class="container">
      <b-row class="flex-reverse">
        <b-col lg="6" md="12" class="text-left p-40">
          <div class="playfair playfair-display-sm-400 pb-24">
            Reach us directly
          </div>
          <div class="inter inter-body-md-600 pb-10">
            Nine Plus Interior Design (M) Sdn Bhd
          </div>
          <div class="inter inter-body-md-400 gray-400 pb-24 ">
            C-11-4, Medan Connaught Centre Point, Jalan 3/144A 
          </div>
          <div class="inter inter-body-md-400 contact-border">
            Monday to Friday 10:00am - 7:00pm
            <br>
            <br>
            <a class="black" :href="'tel:+60167330321'" >+60 16 733 0321</a> - Sara<br>
            <a class="black" :href="'tel:+60123233282'" >+60 12 323 3282</a> - Christine
            <br><br>
            9plusinterior@gmail.com
          </div>
        </b-col>
        <b-col lg="6" md="12">
          <form @submit.prevent="sendEmail">
            <div>
              <label for="name" class="inter inter-body-lg-400">Full name</label>
              <input type="text" id="name" v-model="formData.name" placeholder="Your full name"
                class="form-placeholder name-input">
              <p v-if="nameErrorMessage" style="color: red;">{{ nameErrorMessage }}</p>
            </div>

            <div>
              <label for="email" class="inter inter-body-lg-400">Email</label>
              <input type="email" id="email" v-model="formData.email" placeholder="Your email address"
                class="form-placeholder mail-input">
              <p v-if="emailErrorMessage" style="color: red;">{{ emailErrorMessage }}</p>
            </div>

            <div>
              <label for="phone" class="inter inter-body-lg-400">Phone Number</label>
              <input
                type="text"
                id="phone"
                v-model="formData.phoneNumber"
                @input="validatePhoneNumber"
                placeholder="+60123456789"
                class="form-placeholder call-input"
              />
              <p v-if="phoneNumberErrorMessage" style="color: red;">{{ phoneNumberErrorMessage }}</p>
            </div>

            <div>
              <label for="projectType" class="inter inter-body-lg-400">Project Type</label>
              <select v-model="formData.projectType" class="form-placeholder cabin-input black">
                <option disabled value="">Select type</option>
                <option>Bungalow</option>
                <option>Semi-D</option>
                <option>Link house/Condo</option>
                <option>Design Concept</option>
              </select>
              <p v-if="projectTypeErrorMessage" style="color: red;">{{ projectTypeErrorMessage }}</p>
            </div>

            <div>
              <button class="gray-100 inter inter-body-lg-600" type="submit">Send message <img
                  src="../assets/img/icon/arrow_forward.svg" alt=""></button>
            </div>
          </form>
          <div v-if="successMessage" class="success-message">
            <p>{{ successMessage }}</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'HelloWorld',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phoneNumber: '',
        projectType: ''
      },
      nameErrorMessage: '',
      emailErrorMessage: '',
      phoneNumberErrorMessage: '',
      projectTypeErrorMessage: '',
      successMessage: '',
      showSuccessMessage: false
    };
  },
  computed: {
    // Check if the form is invalid (based on validation errors)
    isFormInvalid() {
      return this.nameErrorMessage || this.emailErrorMessage || this.phoneNumberErrorMessage || this.projectTypeErrorMessage;
    }
  },
  methods: {
    formatPhoneNumber() {
      // Automatically format phone number as user types (for example)
      this.formData.phoneNumber = this.formData.phoneNumber.replace(/[^\d+]/g, '');
      if (this.formData.phoneNumber && !/^(\+60\s\d{2}-\d{3}\s\d{4})$/.test(this.formData.phoneNumber)) {
        this.phoneNumberErrorMessage = 'Invalid phone number format';
      } else {
        this.phoneNumberErrorMessage = '';
      }
    },
    validateName() {
      this.nameErrorMessage = this.formData.name ? '' : 'Full name is required';
    },
    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      this.emailErrorMessage = emailPattern.test(this.formData.email) ? '' : 'Please enter a valid email address';
    },
    validatePhoneNumber() {
      // Regular expression for Malaysian phone numbers with +60
      const phonePattern = /^\+60\d{9}$/;

      // Check if the phone number matches the pattern
      this.phoneNumberErrorMessage = phonePattern.test(this.formData.phoneNumber)
        ? '' // Clear error if valid
        : 'Invalid phone number format. Use the format: +60123456789'; // Show error if invalid
    },
    validateProjectType() {
      this.projectTypeErrorMessage = this.formData.projectType
        ? '' // Valid selection
        : 'Please select a project type'; // Error if not selected
    },
    validateForm() {
      // Run all validation methods
      this.validateName();
      this.validateEmail();
      this.validatePhoneNumber();
      this.validateProjectType();

      // Check if any error messages are set
      return !this.isFormInvalid;
    },
    sendEmail() {
      const templateParams = {
        from_name: this.formData.name,
        from_email: this.formData.email,
        from_phoneNumber: this.formData.phoneNumber,
        from_projectType: this.formData.projectType,
      };

      if (this.validateForm()) {
        // Start the process of sending email
        emailjs.send('service_cp0xtdy', 'template_7q9uulh', templateParams, 'DjBEaRJCqhoRXhwUf')
          .then(() => {
            // Successfully sent the email
            this.successMessage = 'Your message has been sent successfully!';
            this.showSuccessMessage = true;

            // Optionally, clear the success message after 5 seconds
            setTimeout(() => {
              this.showSuccessMessage = false;
              this.successMessage = '';
              this.formData = {
              name: '',
              email: '',
              phoneNumber: '',
              projectType: '',
            };
            }, 5000);
          }, (error) => {
            // Handle email sending error
            console.error('Error sending email:', error);
          });
      }
    }
  },
  watch: {
    'formData.phoneNumber': 'validatePhoneNumber',
    'formData.name': 'validateName',
    'formData.email': 'validateEmail',
    'formData.projectType': 'validateProjectType',
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input,
select {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #F2F2F7;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  /* color: #AEAEB2; */
}

.success-message {
  position: fixed;
  /* Position it fixed on the screen */
  top: 50%;
  /* Position it vertically in the center */
  left: 50%;
  /* Position it horizontally in the center */
  transform: translate(-50%, -50%);
  /* Adjust to make sure it's perfectly centered */
  padding: 15px 30px;
  background-color: #d4edda;
  /* Light green background */
  color: #155724;
  /* Dark green text */
  border: 1px solid #c3e6cb;
  /* Green border */
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  z-index: 9999;
  /* Make sure it's on top of other content */
}

.success-message p {
  padding-bottom: 0;
}

form {
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.form-placeholder {
  background-repeat: no-repeat;
  background-position: left center;
  /* background-size: 20px 20px;  */
  padding-left: 40px;
  background-position-x: 8px;
  color: #1C1C1E;
}

.form-placeholder:hover,
.form-placeholder:focus,
.form-placeholder:focus-visible {
  outline: 0;
  border-color: #e2e2e2 !important;
  /* Highlight border on hover/focus */
}

/* Optional: Style dropdown options (requires JavaScript for full customization) */
.form-placeholder option {
  background: #fff;
  /* Dropdown background */
  color: #333;
  /* Text color */
}

.name-input {
  background-image: url(../assets/img/icon/form-name.svg);
}

.mail-input {
  background-image: url(../assets/img/icon/form-mail.svg);
}

.cabin-input {
  background-image: url(../assets/img/icon/form-cabin.svg);
}

.call-input {
  background-image: url(../assets/img/icon/form-call.svg);
}

form div {
  width: 50%;
  display: inline-block;
  text-align: left;
  padding-bottom: 40px;
  padding-right: 24px;
}

form button {
  background-color: black;
  padding: 14px;
  border: none;
  width: 180px;
}

form button img {
  width: 20px;
  padding-left: 5px;
}

.contact-component {
  padding-top: 72px;
}

.contact-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.contact-border {
  border-left: 1px solid black;
  padding-left: 16px;
}

.container .flex-reverse {
  display: flex;
  flex-direction: unset;
  padding-bottom: 90px;
}
.contact-text-img{
  width: 20%;
}
@media (max-width: 992px) {
  .container .flex-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  form div {
    width: 100%;
    padding-right: 0;
  }

  form div:last-child {
    text-align: center;
  }

  .p-40 {
    padding: 40px;
  }
  .contact-text-img{
    width: 70%; 
  }
}
</style>
