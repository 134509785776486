<template>
  <div>
    <!-- Navbar -->
    <b-navbar toggleable="lg" type="dark" variant="info" :class="{ 'scrolled': scrolled, 'not-home': !isHomePage  }">
      <b-navbar-brand to="Home" tag="router-link">
        <img :src="logoSrc" alt="Logo">
      </b-navbar-brand>

      <!-- Navbar Toggle with conditional icon based on collapse state -->
      <b-navbar-toggle target="navbar-toggle-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x"></b-icon>
          <b-icon v-else icon="list"></b-icon>
        </template>
      </b-navbar-toggle>

      <!-- Navbar Collapse -->
      <b-collapse id="navbar-toggle-collapse" v-model="expanded" is-nav>
        <b-navbar-nav v-if="expanded" class="wheat-bg expanded-bg full-page-navbar">
          <b-navbar-toggle target="navbar-toggle-collapse" class="inter inter-body-lg-600">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="x"></b-icon>
            </template>
          </b-navbar-toggle>
          <b-nav-item to="Home" tag="router-link" class="inter inter-title-md-400">Home. 首页</b-nav-item>
          <b-nav-item to="works" tag="router-link" class="inter inter-title-md-400">Works. 作品</b-nav-item>
          <b-nav-item to="video" tag="router-link" class="inter inter-title-md-400">Videos. 视频</b-nav-item>
          <b-nav-item to="Services" tag="router-link" class="inter inter-title-md-400">Services. 服务</b-nav-item>
          <b-nav-item to="contact" tag="router-link" class="inter inter-title-md-400">Contact. 联络</b-nav-item>
          <div class="nav-footer">
            <div class="row pd-56 g-0">
              <p>
                Contact us
                <br>
                Monday to Friday 10:00am - 7:00pm
              </p>
            </div>
            <div class="row g-0">
              <div class="col-12 flex-center">
                <img src="../assets/img/icon/call.svg">
                <p class="mb-0 details">
                  <a class="black" :href="'tel:+60167330321'" >+60 16 733 0321</a> - Sara
                  <br>
                  <a class="black" :href="'tel:+60123233282'" >+60 12 323 3282</a> - Christine
                </p>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-12 flex-center">
                <img src="../assets/img/icon/mail.svg">
                <p class="mb-0 details ">
                  9plusinterior@gmail.com
                </p>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-12 flex-center">
                <img src="../assets/img/icon/distance.svg">
                <p class="mb-0 details">
                  C-11-4, Medan Connaught Centre Point, Jalan 3/144A 
                </p>
              </div>
            </div>
            <div class="social-media">
              <div class="row g-0">
                <div class="col-12 flex-center">
                  <li><a href="https://www.facebook.com/9plusinterior" target="_blank"><img src="../assets/img/icon/facebook.svg" alt="Facebook"> </a></li>
                  <li><a href="https://youtube.com/@nineplusinteriordesign7138" target="_blank"><img src="../assets/img/icon/youtube.svg" alt="Youtube"> </a></li>
                  <li><a href="https://www.instagram.com/9plusinterior/?hl=en" target="_blank"><img src="../assets/img/icon/insta.svg" alt="instagram"> </a></li>
                  <li><a href="https://wa.link/evxput" target="_blank"><img src="../assets/img/icon/whatsapp.svg" alt="whatsapp"> </a></li>
                </div>
              </div>
            </div>
          </div>

        </b-navbar-nav>
        <b-navbar-nav v-else class="inter inter-body-lg-600">
          <b-nav-item to="works" tag="router-link">Works</b-nav-item>
          <b-nav-item to="video" tag="router-link">Videos</b-nav-item>
          <b-nav-item to="Services">Services</b-nav-item>
          <b-nav-item to="contact" tag="router-link">Contact</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

export default {
  name: 'navComponent',
  data() {
    return {
      expanded: false,
      scrolled: false,
    };

  },
  computed: {
    isHomePage() {
      return this.$route.name === 'Home'; // Replace 'Home' with the exact route name of your Home component
    },
    logoSrc() {
      if ((this.isHomePage) && !this.scrolled) {
        return require('@/assets/img/icon/logo_white.svg');
      }
      return require('@/assets/img/icon/logo_black.svg');
    },
  },
  watch: {
    isHomePage(newVal) {
      this.updateNavbarStyles(newVal);
    },
  },
  mounted() {
    // Listen for the scroll event
    window.addEventListener('scroll', this.onScroll);
    this.updateNavbarStyles((this.isHomePage));
    this.$router.afterEach((to) => {
      this.updateNavbarStyles((to.name ===  this.isHome) );
    });
  },
  destroyed() {
    // Remove the scroll event listener when the component is destroyed
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {

    onScroll() {
      // Check if the page has been scrolled more than 50px
      if (window.scrollY > 50) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    updateNavbarStyles(isHome) {
  const navbar = document.querySelector('.bg-info');
  if (navbar) {
    if (isHome) {
      // Set navbar styles for Home or whiteWood
      navbar.style.backgroundColor = this.scrolled ? 'white' : 'transparent';
      navbar.style.color = this.scrolled ? 'black' : 'white';
      navbar.style.borderBottom = this.scrolled ? '1px solid black' : '1px solid white';

      // Update nav-link colors based on scroll
      navbar.querySelectorAll('.nav-link').forEach(link => {
        // Set nav-link color to white or black depending on scroll position
        link.style.color = this.scrolled ? 'black' : 'white';
      });
    } else {
      // Default styles for other pages
      navbar.style.backgroundColor = 'white';
      navbar.style.color = 'black';
      navbar.style.borderBottom = '1px solid black';
      navbar.querySelectorAll('.nav-link').forEach(link => {
        link.style.color = 'black';
      });
    }
  }
}

  }
}
</script>

<style scoped>
.navbar-collapse {
  justify-content: flex-end;
  margin-right: 5rem;
}

.navbar-brand {
  color: black;
}

.bg-info {
  background-color: transparent !important;
  position: fixed;
  z-index: 3;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid black;
}

.navbar-brand {
  padding: 1rem;
}

.navbar-expand-lg {
  border-bottom: 1px solid white;
  height: 72px;
  padding: 0 143px;
}

#navbar-toggle-collapse {
  margin-right: 0;
}

.bg-info .nav-link {
  color: white;
}

.navbar-toggler-icon {
  background-color: white;
  /* Make hamburger icon white */
  border: none;
}

.navbar-toggler {
  color: white;
  padding: 16px 20px;
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.full-page-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #FCF5ED;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
}

.full-page-navbar .navbar-toggler {
  text-align: right;
  border-bottom: 1px solid black;
  border-radius: unset;
  height: 72px;
  color: black;
}

.full-page-navbar .nav-link {
  padding: 32px 16px 0;
  color: black !important;
}

.b-icon.bi {
  height: 32px;
  width: 32px;
}

.nav-footer {
  border-top: 1px solid black;
  margin-top: 56px;
  color: black;
}

.nav-footer .social-media img {
  height: 40px;
  width: 40px;
  margin-right: 16px
}
.nav-footer img {
  height: 32px;
  width: 32px;
  margin-right: 16px 
}
.nav-footer .details {
  text-align: left;
  width: 215px;
}

.pd-56 {
  padding-top: 56px;
}

.nav-footer .row {
  padding-bottom: 40px;
}

.social-media {
  border-top: 1px solid black;
  padding-top: 40px;
}

.nav-item {
  width: 78px;
  margin-right: 24px;
}

.full-page-navbar .nav-item {
  width: 100%;
  margin-right: 0;
}

.scrolled {
  background-color: white !important;
  /* Change the navbar's background to white when scrolled */
  color: black !important;
  border-bottom: 1px solid black !important;
  /* Change text color to black */
}

.scrolled .nav-link,
.scrolled .navbar-toggler,
.not-home .navbar-toggler {
  color: black !important;
}

@media (max-width: 576px) {
  .bg-info {
    margin: 0;
    /* Set padding to 0 for mobile sizes */
    padding: 0;
  }

  .navbar-expand-lg {
    padding: 0;
  }
  
}
</style>
