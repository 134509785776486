<template>
  <div class="works-component pt-72">
    <div class="amalfi-coast-title amalfi-coast">
      Videos.
    </div>
    <div class="noto noto-display-xl-400 gray-400 letter-spacing">作品视频</div>
    <div class="works-tab container">
      <div class="row"  :class="{ 'fixed': isFixed  }" >
        <b-tabs content-class="mt-3">
          <b-tab title="ALL" active>
            <b-row sm>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/GaxUGV-0fvw?si=o76wak4P5eHvHv-W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">白 + 木 | White + Wood</div>
                  <span class="inter inter-body-md-400 gray-400">USJ 5 | Subang Jaya</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/2vGHtkrwj_s?si=gxcEXFo3qmLAdLK6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">漫乐 | Romance in the house</div>
                  <span class="inter inter-body-md-400 gray-400">Eco Spring | Johor Bahru</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/GsiKMsGn_fM?si=Zs2azHx0u2UH4emu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">沐光  | MU</div>
                  <span class="inter inter-body-md-400 gray-400">Taman Cantik | Cheras</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/sPmS74RxLyE?si=8_V-VNnhjmRCkt4H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">悠 | Serenity</div>
                  <span class="inter inter-body-md-400 gray-400">Sunway Mont | Mont Kiara</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/CBtIZxpO-zw?si=U-1IT3p8TZbcIWxA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">舒窝 | Comfort Zone</div>
                  <span class="inter inter-body-md-400 gray-400">Sunway Mont | Mont Kiara</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/ptpSWjB-hSw?si=SiLXfhTgCWyoOH9Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">岩灰 | Grey Song</div>
                  <span class="inter inter-body-md-400 gray-400">Kairaville | Mont Kiara</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/ih1tN9KAhZo?si=eQH2zySThAjv6-2U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">樱 | Sakura</div>
                  <span class="inter inter-body-md-400 gray-400">Rimbun Vista | Seremban</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="BUNGALOW">
            <b-row sm>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/GaxUGV-0fvw?si=o76wak4P5eHvHv-W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">白 + 木 | White + Wood</div>
                  <span class="inter inter-body-md-400 gray-400">USJ 5 | Subang Jaya</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="SEMI-D">
            <b-row sm>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/2vGHtkrwj_s?si=gxcEXFo3qmLAdLK6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">漫乐 | Romance in the house</div>
                  <span class="inter inter-body-md-400 gray-400">Eco Spring | Johor Bahru</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="LINK HOUSE / CONDO">
            <b-row sm>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/GsiKMsGn_fM?si=Zs2azHx0u2UH4emu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">沐光  | MU</div>
                  <span class="inter inter-body-md-400 gray-400">Taman Cantik | Cheras</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/sPmS74RxLyE?si=8_V-VNnhjmRCkt4H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">悠 | Serenity</div>
                  <span class="inter inter-body-md-400 gray-400">Sunway Mont | Mont Kiara</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/CBtIZxpO-zw?si=U-1IT3p8TZbcIWxA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">舒窝 | Comfort Zone</div>
                  <span class="inter inter-body-md-400 gray-400">Sunway Mont | Mont Kiara</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/ptpSWjB-hSw?si=SiLXfhTgCWyoOH9Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">岩灰 | Grey Song</div>
                  <span class="inter inter-body-md-400 gray-400">Kairaville | Mont Kiara</span>
                </div>
              </b-col>
              <b-col md="4" sm>
                <iframe width="384" height="256" src="https://www.youtube.com/embed/ih1tN9KAhZo?si=eQH2zySThAjv6-2U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div class="pd-16">
                  <div class="playfair playfair-title-sm-400 black">樱 | Sakura</div>
                  <span class="inter inter-body-md-400 gray-400">Rimbun Vista | Seremban</span>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <!-- <b-tab title="DESIGN CONCEPT">
            <b-row sm>
              <p>Coming Soon</p>
            </b-row>
          </b-tab> -->
        </b-tabs>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'videoComponent',
  data() {
    return {
      isFixed: false, 
    }
  },
  methods: {
    handleScroll() {
      const navTabs = document.querySelector('.nav-tabs');
      if (window.scrollY > navTabs.offsetTop) { 
        this.isFixed = true;
      } else { 
        this.isFixed = false;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

}
</script>

<style>
.pt-72 {
  padding-top: 72px;
}

.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}

.nav {
  justify-content: center;
}

.works-component .nav-link {
  width: 210px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8E8E93;
  padding: 32px 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: 2px solid black !important;
  border: 0;
  color: black;
}
.fixed .nav-tabs {
  position: fixed; /* When scrolled, this will make it fixed */
  top: 72px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
}

.works-tab {
  padding-bottom: 56px;
}

.works-tab .row .col {
  padding-top: 56px;

}

img {
  width: 100%
} 
@media (max-width: 576px) {
  .works-component .nav-link {
    width: 114px;
    padding: 16px 0 16px;
  }
  iframe {
    width: 100%
  } 
  .works-component .nav-item:nth-child(1) .nav-link,
  .works-component .nav-item:nth-child(2) .nav-link,
  .works-component .nav-item:nth-child(3) .nav-link {
    border-bottom: 1px solid #dee2e6;
  }

  .works-component .nav-item:nth-child(4) .nav-link,
  .works-component .nav-item:nth-child(5) .nav-link {
    width: 132px;
  }
  .nav-tabs .nav-item:nth-child(4).show .nav-link,
  .nav-tabs .nav-item:nth-child(4) .nav-link.active,
  .nav-tabs .nav-item:nth-child(5).show .nav-link,
  .nav-tabs .nav-item:nth-child(5) .nav-link.active {
    border-top: 1px solid #dee2e6 !important; 
  }
}
</style>