<template>
  <div class="works-component muguang">
    <div class="banner"> 
      <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/00-hero banner.jpg" alt="">
    </div>
    <div class="border-bottom-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 black text-left m-0">
          沐光 | Mu
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-1px">
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/01-full-facade-1.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/02-landscape-facade-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/02-landscape-facade-2.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/03-full-liv-1.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/04-landscape-liv-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/04-landscape-liv-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/04-landscape-liv-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/05-landscape-liv-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/05-landscape-liv-2.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/06-full-liv-1 1.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/07-landscape-stair-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/07-landscape-stair-2.jpg" alt="">
        </b-col> 
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/07-landscape-stair-3.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/08-landscape-liv-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/08-landscape-liv-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/08-landscape-liv-3.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/09-portrait-dry-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/09-portrait-dry-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/09-portrait-dry-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/10-landscape-dry-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/10-landscape-dry-2.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/11-landscape-wet-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/11-landscape-wet-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/11-landscape-wet-3.jpg" alt="">
        </b-col>
      </b-row>   
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/12-portrait-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/12-portrait-2.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/13-full-family.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/14-full-R1+2-1 1.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/15-landscape-R1+2-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/15-landscape-R1+2-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/15-landscape-R1+2-3.jpg" alt="">
        </b-col>
      </b-row>  
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/16-landscape-R1+2-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/16-landscape-R1+2-2.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/17-landscape-master-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/17-landscape-master-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/17-landscape-master-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/18-full-master-1.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/19-landscape-master-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/19-landscape-master-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/19-landscape-master-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/01-沐光 MU-TAMAN CANTIK-KUALA LUMPUR/20-full-1.jpg" alt="">
        </b-col>
      </b-row> 
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="Perfect-In-Black"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 black  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">墨 | Perfect in Black</p>
              <p class="inter inter-body-md-400 black p-0">Wira Height Sg Long | Kajang</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 black  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">舒窝 | Comfort Zone</p>
              <p class="inter inter-body-md-400 black p-0">Sunway Mont | Mont Kiara</p>
            </b-col>
            <b-col cols="3"  to="Comfort-Zone"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'muGuang',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.muguang{
  background-color: #D9D9D9 ;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.muguang p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>