<template>
  <div class="works-component modernLuxurious1">
    <div class="banner"> 
      <img src="../../../assets/img/works/design concept-1500x1500/15-复古怀旧风-RETRO-JALAN GASING-PETALING JAYA/1.jpg" alt="">
    </div>
    <div class="border-bottom-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 black text-left m-0">
          复古怀旧风 | Retro
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-1px"> 
      <b-row sm>
        <b-col v-for="(img, index) in images" :key="index" md="6" sm>
          <img  class="image-fixed-height" :src="require(`@/assets/img/works/design concept-1500x1500/15-复古怀旧风-RETRO-JALAN GASING-PETALING JAYA/${img}`)" :alt="'Image ' + (index + 1)">
        </b-col> 
      </b-row>
       
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="modern-contemporary-2"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 black  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">现代简约风 | Modern Contemporary</p>
              <p class="inter inter-body-md-400 black p-0">Equine Park | Seri Kembangan</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 black  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">现代简约风 | Modern Contemporary</p>
              <p class="inter inter-body-md-400 black p-0">Armanee Terrence | Damansara Perdana</p>
            </b-col>
            <b-col cols="3"  to="modern-contemporary-4"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'RetroContactComponent',
  data() {
    return { 
      images: [ 
        "2.jpg", 
        "3.jpg", 
        "4.jpg",
        "5.jpg",  
        "6.jpg", 
        "7.jpg", 
        "8.jpg", 
        "9.jpg", 
        "10.jpg",  
        "11.jpg",
        "12.jpg",
        "13.jpg", 
        "14.jpg",
        "15.jpg",  
        "16.jpg", 
        "17.jpg", 
        "18.jpg", 
        "19.jpg",
        "20.jpg",  
        "21.jpg",
        "22.jpg",
        "23.jpg", 
        "24.jpg",
        "25.jpg",  
        "26.jpg", 
        "27.jpg",
      ]
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.modernLuxurious1{
  background-color: #FFF ;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.modernLuxurious1 p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}
.image-fixed-height {
  width: 100%;  /* Ensures image takes full width of the column */
  height: 377px; /* Set a fixed height (adjust as needed) */
  object-fit: cover; /* Ensures images fill the space without distortion */
  display: block; /* Removes extra spacing below inline images */
}
@media (max-width: 576px) { 
  .image-fixed-height {
    height: auto;
  }
}
</style>