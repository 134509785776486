<template>
  <div class="works-component grey-song">
    <div class="banner"> 
      <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/00-hero banner.jpg" alt="">
    </div>
    <div class="border-bottom-white-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 white text-left m-0">
          岩灰 | Grey Song
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-white-1px"> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/01-land-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/01-land-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/02-portrait-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/02-portrait-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/03-land-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/03-land-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/03-land-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/04-land-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/04-land-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/05-full.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/06-land-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/06-land-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/06-land-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/07-full.jpg" alt="">
        </b-col> 
      </b-row> 
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/08-land-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/08-land-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/08-land-3.jpg" alt="">
        </b-col>
      </b-row> 
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/09-portrait-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/09-portrait-2.jpg" alt="">
        </b-col> 
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/10-land-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/10-land-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/10-land-3.jpg" alt="">
        </b-col>
      </b-row>  
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/11-full.jpg" alt="">
        </b-col>
      </b-row>
      
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/12-land-1.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/12-land-2.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/13-land-1.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/13-land-2.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/link-house-condo/03-岩灰-GREY SONG -KIARAVILLE -MONT KIARA/13-land-3.jpg" alt="">
        </b-col>
      </b-row>  
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <b-row class="flex-center">
            <b-col cols="3" to="Comfort-Zone"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_left_white.svg"></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 white  p-0">PREVIOUS PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">舒窝 | Comfort Zone</p>
              <p class="inter inter-body-md-400 white p-0">Sunway Mont | Mont Kiara</p>
            </b-col> 
          </b-row>
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 white  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 white p-0">悠 | Serenity</p>
              <p class="inter inter-body-md-400 white p-0">Sunway Mont | Mont Kiara</p>
            </b-col>
            <b-col cols="3"  to="Serenity"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right_white.svg" alt=""></b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'greySong',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.grey-song{
  background-color: #7F7F7F ;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.grey-song p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>