<template>
  <div class="works-component white-wood">
    <div class="banner">
      <img src="../../../assets/img/works/bungalow/00-hero-banner.jpg" alt="">
      
    </div>
    <div class="border-bottom-1px">
      <div class="container ">
        <p class="playfair playfair-display-sm-400 black text-left m-0">
          白+木 | White and Wood
        </p>
      </div>
    </div>
    <div class="container p-112 works-img border-bottom-1px">
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/01-landscape-foyer-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/01-landscape-foyer-02.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/02-landscape-dining-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/02-landscape-dining-02.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/03-landscape-dry-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/03-landscape-dry-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/03-landscape-dry-03.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/bungalow/04-full-dry-01.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/05-landscape-living-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/05-landscape-living-02.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/06-landscape-living-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/06-landscape-living-02.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/07-portrait-living-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/07-portrait-living-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/07-portrait-living-03.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/08-landscape-master-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/08-landscape-master-02.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/bungalow/09-full-master-01.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/10-landscape-master-01.jpg" alt="">
        </b-col>
        <b-col md="6" sm>
          <img src="../../../assets/img/works/bungalow/10-landscape-master-02.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/bungalow/11-full-daughter-01.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/12-portrait-master-01.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/12-portrait-master-02.jpg" alt="">
        </b-col>
        <b-col md="4" sm>
          <img src="../../../assets/img/works/bungalow/12-portrait-master-03.jpg" alt="">
        </b-col>
      </b-row>
      <b-row sm>
        <b-col>
          <img src="../../../assets/img/works/bungalow/13-full-masterbath-01.jpg" alt="">
        </b-col>
      </b-row>
      
    </div>
    <div class="container p-112 works-img">
      <b-row >
        <b-col md="4" class="pb-24"> 
          <!-- <b-row class="flex-center">
            <b-col cols="3"><img class="icon-expand" src="../../../assets/img/icon/expand_circle_left.svg" alt=""></b-col>
            <b-col cols="9" class="text-right">
              <p class="inter inter-body-md-600 black  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">蓝色小时 | Bluberry Hours</p>
              <p class="inter inter-body-md-400 black p-0">Semi D, Eco Spring | Johor</p>
            </b-col> 
          </b-row> -->
        </b-col>
        <b-col md="4" sm> 
        </b-col>
        <b-col md="4" sm> 
          <b-row class="flex-center">
            <b-col cols="9" class="text-left">
              <p class="inter inter-body-md-600 black  p-0">NEXT PROJECT</p>
              <p class="playfair playfair-title-lg-400 black p-0">归 | Home</p>
              <p class="inter inter-body-md-400 black p-0">Longbranch | Kota Kemunting</p>
            </b-col>
            <b-col cols="3"  to="Gui-Home"  tag="router-link"><img class="icon-expand cursor-pointer" src="../../../assets/img/icon/expand_circle_right.svg" alt=""></b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'whiteWood',
  data() {
    return { 
    }
  },
  methods: { 
  }, 

}
</script>

<style>
 
.white-wood{
  background-color: #DBD7D6;
}
.banner img, .works-img img{
  width: 100%;
}
.works-img img{
  padding-bottom: 24px;
}
.white-wood p {
  padding: 24px 0;  
}
.p-112{
  padding: 112px 0 88px;
}
.works-component .amalfi-coast-title {
  padding-bottom: 4rem;
}

.letter-spacing {
  letter-spacing: 0.5em;
  padding-bottom: 56px
}
img.icon-expand{
  width: auto;
}

@media (max-width: 576px) { 
}
</style>